<div id="markUp">
    <div title="Help" id="helpTool" class="tool helpBtn" data-type="help" (click)="helpClick()"></div>
    <button title="Clear All Markup" id="remove" class="tool" (click)="drawingTool('remove')"></button>

    <button title="Type Tool" *ngIf="version ==='page'" id="type" class="tool drawTool"
        (click)="drawingTool('type')"></button>
    <button title="Type Tool" *ngIf="version !=='page'" id="pdfType" class="tool"
        (click)="drawingTool('pdfType')"></button>

    <div id="colorTool" class="tool colorBtn" data-type="color" (click)="colorSelect()">
        <div id="sel_color" title="Color Selection" [ngStyle]="{ background: selectedColor }"></div>
        <div id="colorPallete" *ngIf="showColorPallete">
            <div class="colorOpt" [title]="color" [class.colorSelected]="selectedColor === color"
                *ngFor="let color of colorPallete" [ngStyle]="{ background: color }" (click)="colorOptionChange(color);">
            </div>
        </div>
    </div>
    <button title="Text Highlight" *ngIf="version === 'page'" id="highlighter" class="tool"
        (click)="drawingTool('highlighter')"></button>
    <button title="Underline" *ngIf="version === 'page'" id="underliner" class="tool"
        (click)="drawingTool('underliner')"></button>
    <div title="Highlighter" id="rectangleTool" class="tool  drawTool" data-type="rectangle"
        (click)="drawingTool('rectangleTool')"></div>
    <div title="Circle" id="circleTool" class="tool drawTool" data-type="circle" (click)="drawingTool('circleTool')">
    </div>
    <div title="Line" id="lineTool" class="tool drawTool" data-type="line" (click)="drawingTool('lineTool')"></div>
    <div title="Marker" id="markerTool" class="tool drawTool" data-type="marker" (click)="drawingTool('markerTool')">
    </div>
    <button title="Eraser" id="eraser" class="tool" (click)="drawingTool('eraser')"></button>
    <div title="Save" id="saveTool" class="tool saveBtn" data-type="save" (click)="drawingTool('saveTool')"></div>
    <div id="savingText" *ngIf="showSavingText">
        <div>Saving</div><span>.</span><span>.</span><span>.</span>
    </div>
</div>

<app-custom-alert [alertOptions]="alertOptions"></app-custom-alert>