import { Injectable } from '@angular/core';

@Injectable({
    'providedIn': 'root'
})
export class SessionService {
    assessmentQuestionIndex = 0;
    attemptId = 0;

    constructor() { }

    /**
     * Gets the launcher details from session storage.
     */
    get launcherDetails() {
        const data = sessionStorage.getItem('launcherDetails') || '{}';
        return JSON.parse(data);
    }

    /**
     * Sets the launcher details into session storage.
     */
    set launcherDetails(data: any) {
        sessionStorage.setItem('launcherDetails', JSON.stringify(data));
    }

    /**
     * Gets the course details from session storage.
     */
    get courseDetails() {
        const data = sessionStorage.getItem('courseDetails') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the course details into session storage.
     */
    set courseDetails(data: any) {
        sessionStorage.setItem('courseDetails', JSON.stringify(data));
    }

    /**
     * Gets the coursemap details from session storage.
     */
    get courseMapDetails() {
        const data = sessionStorage.getItem('courseMapDetails') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the coursemap details into session storage.
     */
    set courseMapDetails(data: any) {
        sessionStorage.setItem('courseMapDetails', JSON.stringify(data));
    }

    /**
     * Gets the myday page view details from session storage.
     */
    get myDayViewDetails() {
        const data = sessionStorage.getItem('myDayViewDetails') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the myday page view details into session storage.
     */
    set myDayViewDetails(data: any) {
        sessionStorage.setItem('myDayViewDetails', JSON.stringify(data));
    }

    /**
     * Gets the task list from session storage.
     */
    get taskListData() {
        const data = sessionStorage.getItem('taskListData') || '{}';
        return JSON.parse(data);
    }

    /**
     * Sets the task list into session storage.
     */
    set taskListData(data: any) {
        sessionStorage.setItem('taskListData', JSON.stringify(data));
    }

    /**
     * Gets the lesson details from session storage.
     */
    get lessonDetails() {
        const data = sessionStorage.getItem('lessonDetails') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the lesson details into session storage.
     */
    set lessonDetails(data: any) {
        sessionStorage.setItem('lessonDetails', JSON.stringify(data));
    }

    /**
     * Gets the assessment details from session storage.
     */
    get assessmentDetails() {
        const data = sessionStorage.getItem('assessmentDetails') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the assessment details into session storage.
     */
    set assessmentDetails(data: any) {
        sessionStorage.setItem('assessmentDetails', JSON.stringify(data));
    }

    /**
     * Gets the preferences from session storage.
     */
    get preferences() {
        const data = sessionStorage.getItem('preferences') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the preferences into session storage.
     */
    set preferences(data: any) {
        sessionStorage.setItem('preferences', JSON.stringify(data));
    }

    /**
     * Gets the audio state from local storage.
     */
    get audioState() {
        return localStorage.getItem('AudioState');
    }

    /**
     * Sets the audio state into local storage.
     */
    set audioState(data: any) {
        localStorage.setItem('AudioState', data);
    }

    /**
     * Gets the header info from session storage.
     */
    get headerInfo() {
        const data = sessionStorage.getItem('headerInfo') || '{}';
        return Object.assign({}, JSON.parse(data));
    }

    /**
     * Sets the header info from session storage.
     */
    set headerInfo(data: any) {
        sessionStorage.setItem('headerInfo', JSON.stringify(data));
    }
  
    get forums_url() {
        const data = sessionStorage.getItem('forums_url') || '{}';
        return JSON.parse(data);
    }

    set forums_url(data: any) {
        sessionStorage.setItem('forums_url', JSON.stringify(data));
    }
    
    get preview_url() {
        const data = sessionStorage.getItem('preview_url') || '{}';
        return JSON.parse(data);
    }

    set preview_url(data: any) {
        sessionStorage.setItem('preview_url', JSON.stringify(data));
    }
}