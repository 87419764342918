import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Utility, SessionService } from './core/services';
import { Subscription } from 'rxjs';
import { LoaderService } from './core/services';
import { LoaderState } from './core/models';
import * as $ from 'jquery';
import 'jquery-ui-touch-punch';

(window as any).jQuery = $;
// require('jquery-ui-touch-punch');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  appTheme = '';
  show = false;
  private subscription: Subscription = new Subscription;

  constructor(@Inject(DOCUMENT) private document: Document, private utility: Utility,
    private sessionService: SessionService, private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.utility.setPageTitle('EdisonLearning-CDS');
    this.document.body.style.backgroundColor = '#bbccdd';
    this.sessionService.audioState = 'default';
    this.utility.themObject.subscribe((data: any) => {
      this.document.body.style.backgroundColor = data.activeColorMD?.toString();
      this.document.body.style.backgroundImage = data.activeColorMD?.toString();
     // $('body').css('background', 'url(assets/images/' + data.activeImageMD + ') no-repeat fixed center center / cover ' + data.activeColorMD + '');
    });
    this.subscription = this.loaderService.loaderState.subscribe((state: LoaderState) => {
      setTimeout(() => this.show = state.show);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
