import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root"
})
export class ApiService {
  private serviceUrl: string;
  private attachmentUrl: string;
  constructor(private http: HttpClient) {
    this.attachmentUrl = environment.appUrl;
    this.serviceUrl = environment.url;
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(this.serviceUrl + path, { params })
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http
      .post(this.serviceUrl + path, body)
      .pipe(catchError(this.formatErrors));
  }

  localGet(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .get(path, { params })
      .pipe(catchError(this.formatErrors));
  }

  upload(path: string, formData: Object = {}): Observable<any> {
    return this.http
      .post(`${this.attachmentUrl}${path}`, formData, { responseType: 'text'})
      .pipe(catchError(this.formatErrors));
  }

  loadXML(path: string): Observable<any> {
    return this.http.get(path, { headers: new HttpHeaders().set('content-type', 'application/xml') , responseType: 'text' }).pipe(catchError(this.formatErrors));
  }
}
