import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ThemeModel, HeaderModel } from '../../models'
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Utility {
  private theam = new ThemeModel();
  private headermodel = new HeaderModel();
  private preference: BehaviorSubject<ThemeModel> = new BehaviorSubject(this.theam);
  private course: BehaviorSubject<HeaderModel> = new BehaviorSubject(this.headermodel);
  private myDayUpdateForumUrl: BehaviorSubject<any> = new BehaviorSubject(null);
  private courseChangeEventSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private notepadValueBindEventSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private modalOverlayEventSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  private myDayModalOverlayEventSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  private stopTTSEventSubject: BehaviorSubject<any> = new BehaviorSubject(false);

  themObject = this.preference.asObservable();
  castUser = this.preference.asObservable();
  courseName = this.course.asObservable();
  updateForumUrl = this.myDayUpdateForumUrl.asObservable();
  courseChangeEvent = this.courseChangeEventSubject.asObservable();
  notepadValueBindEvent = this.notepadValueBindEventSubject.asObservable();
  modalOverlayEvent = this.modalOverlayEventSubject.asObservable();
  myDayModalOverlayEvent = this.myDayModalOverlayEventSubject.asObservable();
  stopTTSEvent = this.stopTTSEventSubject.asObservable();
  private elBaseURL = '';

  constructor(private titleHeader: Title) {
    this.elBaseURL = `${environment.appUrl}`;
  }

  public quicknavconfig: any =
    [{
      "name": "Course Map",
      "link": "#/cp/lesson",
      "img": "coursemap-icon",
      "class": "icon-course-player"
    }, {
      "name": "Forum",
      "link": "#/cp/forum",
      "img": "forum-icon",
      "target": "_blank",
      "class": "icon-forum"
    }, {
      "name": "Calculator",
      "link": " ",
      "img": "calculator-icon",
      "class": "icon-calculator",
      "target": "_blank"
    }, {
      "name": "Traditional View",
      "link": " ",
      "img": "traditionalview-icon",
      "class": "icon-traditional-view",
      "target": "_blank"
    }, {
      "name": "Message Teacher",
      "class": "icon-message-teacher",
      "img": "messageteacher-icon"
    }, {
      "name": "Help",
      "link": " ",
      "img": "help-icon",
      "class": "icon-help",
      "target": "_blank"
    }, {
      "name": "Customize",
      "class": "icon-customize",
      "img": "customize-icon"
    }];

  public backGroundImages: any = [{
    "img": "1.jpg"
  }, {
    "img": "2.jpg"
  }, {
    "img": "3.jpg"
  }, {
    "img": "4.jpg"
  }, {
    "img": "5.jpg"
  }, {
    "img": "6.jpg"
  }, {
    "img": "7.jpg"
  }, {
    "img": "8.jpg"
  }, {
    "img": "9.jpg"
  }, {
    "img": "10.jpg"
  }];

  public backGroundColors: any = [{
    "color": "#3C90CD"
  }, {
    "color": "#709F02"
  }, {
    "color": "#AF2630"
  }, {
    "color": "#D24D6C"
  }, {
    "color": "#835C36"
  }, {
    "color": "#01A196"
  }, {
    "color": "#E98048"
  }, {
    "color": "#F3B90F"
  }, {
    "color": "#513B79"
  }, {
    "color": "#093972"
  }];

  public colors: any = {
    'Social Studies': {
      fillColor: '#D76F2C,#D76F2C',
      strokeColor: '#e88243',
      bgColor: '#aa5622'
    },
    'Science': {
      fillColor: '#82BA50,#82BA50',
      strokeColor: '#95d35b',
      bgColor: '#658e3e'
    },
    'Mathematics': {
      fillColor: '#C35BCC,#C35BCC',
      strokeColor: '#E86EF3',
      bgColor: '#833A8A'
    },
    'Math': {
      fillColor: '#C35BCC,#C35BCC',
      strokeColor: '#E86EF3',
      bgColor: '#833A8A'
    },
    'English': {
      fillColor: '#ECB730,#ECB730',
      strokeColor: '#fcc741',
      bgColor: '#d6a62c'
    },
    'Language Arts': {
      fillColor: '#ECB730,#ECB730',
      strokeColor: '#fcc741',
      bgColor: '#d6a62c'
    },
    'Others': {
      fillColor: '#14B4EC,#14B4EC',
      strokeColor: '#02b9fc',
      bgColor: '#0099d1'
    }
  };

  public themecolor: any = [{
    "fillcolor": "#FCB345,#FCAE39",
    "strokecolor": "#FCA017"
  }, {
    "fillcolor": "#AF98DB,#A78CDB",
    "strokecolor": "#8A60DB"
  }, {
    "fillcolor": "#8ACFFF,#7FCBFF",
    "strokecolor": "#51B7FF"
  }, {
    "fillcolor": "#FF9172,#FF8866",
    "strokecolor": "#FF673C"
  }, {
    "fillcolor": "#6FD9D9,#65D9D9",
    "strokecolor": "#3BD9D9"
  }, {
    "fillcolor": "#ACD85D,#A8D853",
    "strokecolor": "#96D821"
  }, {
    "fillcolor": "#F3D452,#F3D145",
    "strokecolor": "#F3CB25"
  }, {
    "fillcolor": "#E197E5,#E08CE5",
    "strokecolor": "#DE69E5"
  }, {
    "fillcolor": "#FF9CB6,#FF93AF",
    "strokecolor": "#FF648D"
  }, {
    "fillcolor": "#C3B2A2,#C3AC98",
    "strokecolor": "#C39B76"
  }];

  public soundRecorderConfig: any = {
    name: 'Sound Recorder',
    link: '//media-dev.edisonlearning.com/ES/sound_recorder/sound_recorder.html',
    target: '_blank'
  };

  getTraditionalViewUrl(action: number, siteId: any, sitesectionId: any, userId: any) {
    const url = `/o/suite/ContentServlet?STAGE=PROCESS&ACTION=${action}&siteID=${siteId}&sitesection_id=${sitesectionId}&user_id=${userId}`;
    return `${this.elBaseURL}${url}`;
  }

  getHelpUrl() {
    const url = environment.quickNavHelp;
    return url;
  }

  getHelpCalculator() {
    const url = environment.quickNavCalculator;
    return url;
  }

  getForumUrl(forumId: any, siteId: any, userId: any) {
    const url = `/o/suite/RendererServlet?handler=forumHandler&forumID=${forumId}&siteID=${siteId}&user_id=${userId}`;
    return `${this.elBaseURL}${url}`;
  }

  setCourseName(val: HeaderModel) {
    this.course.next(val);
  }

  setPageTitle(value: string) {
    this.titleHeader.setTitle(value);
  }

  setPreference(val: ThemeModel) {
    this.preference.next(val);
  }

  setUpdateForumUrl(val: any) {
    this.myDayUpdateForumUrl.next(val);
  }

  setCourseChangeEventData(val: any) {
    this.courseChangeEventSubject.next(val);
  }

  setNotepadValueBindEvent(val: any) {
    this.notepadValueBindEventSubject.next(val);
  }

  setModalOverlayEventData(val: boolean) {
    this.modalOverlayEventSubject.next(val);
  }

  setMyDayModalOverlayEventData(val: boolean) {
    this.myDayModalOverlayEventSubject.next(val);
  }

  setStopTTSEventData(val: boolean) {
    this.stopTTSEventSubject.next(val);
  }

}
