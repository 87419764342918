export const ApiDictionary = {
  getCourseData: {
    url: 'getCourseData'
  },
  getCoursesYear: {
    url: 'getCourseYear'
  },
  getPreferences: {
    url: 'getPreferences'
  },
  savePreferences: {
    url: 'savePreferences'
  },
  getCourseMap: {
    url: 'getCourseMap'
  },
  getCourseGrades: {
    url: 'getCourseGrades'
  },
  getTaskList:{
    url:'getTaskList'
  },
  getAssessment:{
    url:'getAssessment'
  },
  messageTeacher:{
    url:'messageTeacher'
  },
  startTask: {
    url: 'startTask'
  },
  completeTask: {
    url: 'completeTask'
  },
  saveNotePad: {
    url:'saveNoteBook'
  },
  getNotePad: {
    url :'getNoteBook' 
  },
  startAssessment: {
    url: 'startAssessment'
  },
  saveAssessment: {
    url: 'saveAssessment'
  },
  submitAssessment: {
    url: 'submitAssessment'
  },
  fileAttachment: {
    url: '/o/suite/MessageService'
  },
  deleteFileAttachment: {
    url: 'deleteAssessmentAttachment'
  },
  getMarkup: {
    url: 'getMarkup'
  },
  saveMarkup: {
    url: '/o/suite/MessageService'
  },
  getPageContent: {
    url: 'getPageContent'
  },
  getInput: {
    url: 'getInput'
  },
  saveInput: {
    url: 'saveInput'
  }
}