<div class="notepadBg">
	<div class="notepadHeader">
		Notepad
	</div>
    <div class="ckEditorDiv">
        <ckeditor [(ngModel)]="notepadContent" [config]="ckeConfig" (ngModelChange)="onNotepadTextChange($event)">
        </ckeditor>
    </div>
	<div class="websearch">
		<input type="text" id="webSearchText" [(ngModel)]="searchText" (paste)="webSearchOnPaste($event)" (keypress)="webSearchOnKeyPress($event)"/>
		<div id="webSearch" class="btn btn-default" (click)="redirectPopup()">
			Web Search
		</div>
	</div>
		<div id="closeNotepad" class='closeBtn' (click)="closeNotepad()" [ngStyle]="{'border-color': activeTheme.borderColor, 'background-color': activeTheme.backgroundColor}">
	</div>

	<div class="notepadSaveCancelBtn">
		<div id="cancelBtn" class="btn btn-danger nbutton" (click)="closeNotepad()" >
			Cancel
		</div>
		<div id="saveBtn" class="btn btn-success nbutton" [class.disabledBtn]="disabledSaveBtn" (click)="saveData()">
			Save
		</div>
	</div>
	<div id="htmlFeedBackMsg" *ngIf="feedBackState" [innerHTML]="feedBackMessage"></div>
</div>
<app-custom-alert [alertOptions]="alertOptions"></app-custom-alert>
