import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Pipe({
    name : 'specialChar'
})

export class SpecialChar implements PipeTransform{

    constructor(private sanitizer:DomSanitizer){}

    transform(value: string): any{
        if(typeof value ==='string'){
            return this.sanitizer.bypassSecurityTrustHtml(value)
        }
        else if(value){
            return this.sanitizer.bypassSecurityTrustHtml(value)
        }
        else{
            return "";
        }
    }
    


}

