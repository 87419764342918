import { Injectable } from '@angular/core';

@Injectable()
export class LauncherService {
    basic: any
    constructor() { }
    getBasicDetails() {
        return this.basic;
    }
    sendBasicDetails(req: any) {
        this.basic = req;
    }
}