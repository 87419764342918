import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'launcher',
    loadChildren:
      () => import('./launch/launch.module').then(m => m.LaunchModule),
  },
  {
    path: 'myday',
    loadChildren:
      () => import('./myday/myday-module').then(m => m.MyDayModule),
  },
  {
    path: 'courses',
    loadChildren: () => import('./Course/course.module').then(m => m.CourseModule),
    outlet: 'modal'
  },
  {
    path: '',
    redirectTo: 'launcher',
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
