export const learningActivityImagePath = {
    paintballBlaster: '/assets/images/AssessmentGames/Paintball_Blaster/',
    matchMania: '/assets/images/AssessmentGames/Match_Mania/',
    castleCrunch: '/assets/images/AssessmentGames/CastleCrunch/',
    puppyFetch: '/assets/images/AssessmentGames/PuppyFetch/',
    wathYourStep: '/assets/images/AssessmentGames/watchYourStep/',
    dunkTankDefaultFirsthalf: '/assets/images/AssessmentGames/Dunk_Tank/firsthalf/',
    dunkTankDefaultSecondhalf: '/assets/images/AssessmentGames/Dunk_Tank/secondhalf/',
    dunkTankHandFlump: '/assets/images/AssessmentGames/Dunk_Tank/hand/',
    goForGold: '/assets/images/AssessmentGames/goforgold/',
    monkeySwipe: 'assets/images/AssessmentGames/monkeyswipe/',
    guitarLegends: '/assets/images/AssessmentGames/guitarLegends/',
    magicHat: '/assets/images/AssessmentGames/Magic_Hat/'
};

export const learningActivityRoutingPath: any = {
    game1_type6: 'paintballblaster',
    game1_type7: 'castlecrunch',
    game1_type8: 'watchyourstep',
    game1_type9: 'matchmania',
    game1_type10: 'guitarlegends',
    game2_type6: 'dunktank',
    game2_type7: 'puppyfetch',
    game2_type8: 'monkeyswipe',
    game2_type9: 'magichat',
    game2_type10: 'goforgold'
}

export const ckeEditorToolBarOptions: any = {
    allowedContent: false,
      forcePasteAsPlainText: true,
      removePlugins: 'exportpdf,resize',
      toolbarGroups : [
        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
        { name: 'forms', groups: [ 'forms' ] },
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        '/',
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'links', groups: [ 'links' ] },
        { name: 'insert', groups: [ 'insert' ] },
        { name: 'styles', groups: [ 'styles' ] },
        { name: 'colors', groups: [ 'colors' ] },
        { name: 'tools', groups: [ 'tools' ] },
        { name: 'others', groups: [ 'others' ] },
        { name: 'about', groups: [ 'about' ] }
      ],
      removeButtons : 'Source,Save,Scayt,SelectAll,NewPage,ExportPdf,Flash,Preview,Print,Templates,PasteText,PasteFromWord,Undo,Redo,Find,Replace,Form,Checkbox,Radio,TextField,CopyFormatting,RemoveFormat,Strike,Subscript,Superscript,Textarea,Select,Button,ImageButton,HiddenField,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,Language,BidiRtl,BidiLtr,Link,Unlink,Anchor,Image,Table,HorizontalRule,Smiley,PageBreak,Iframe,TextColor,BGColor,ShowBlocks,Maximize,About,Styles,Format,Font,FontSize'
}
