import { Component, OnInit, Input, Output, HostListener, OnDestroy, EventEmitter } from '@angular/core';
import { fabric } from 'fabric';
import { SessionService, elService } from '../../../core/services';

declare var rangy: any;
declare var $: any;

@Component({
  selector: 'app-draw-over',
  templateUrl: './draw-over.component.html',
  styleUrls: ['./draw-over.component.scss']
})
export class DrawOverComponent implements OnInit, OnDestroy {
  colorPallete: any[] = ['yellow', 'orange', 'black', 'purple', 'red', 'green', 'blue', 'brown'];
  showColorPallete = false;
  colorPicked = false;
  selectedColor = '';
  showSavingText = false;
  currentPageIndex = 0;
  alertOptions: any = null;
  drawingObject: any = {};
  serializedPdfTypeToolPool: any;
  serializedInputPool: any;
  serializedCanvasPool: any;
  previousTool: any = null;
  canvas: any = null;
  activeCanvas: any = null;
  textAreaInput: any = null;
  availableTypeTool = 0;
  rect: any = null;
  line: any = null;
  ellipse: any = null;
  isPageDirty = false;
  typer: any = null;
  cssApplier: any = null;
  highlighter: any = null;
  underliner: any = null;
  pdfTypeToolCount = 0;
  canvasPool: any = {};
  htmlData: any = null;
  canvasData: any = null;
  initialCanvasData: any = {};
  area = '';
  isSaveRequired: any = {};
  userInputId: any;
  previousHighlightColor: any;
  newHighlightColor: any;
  drawingCanvasSelected = false;
  previousUnderlineColor: any;
  previousRectangleColor: any;
  previousCircleColor: any;
  previousLineColor: any;
  previousMarkerColor: any;
  htmlDataToCovertPDF = '';
  prevScale = 1.2;

  @Input('version') version: string = '';
  @Input('canvasDetails')
  set canvasDetails(data: any) {
    if (data) {
      this.area = data.area;
      const isiPad = navigator.userAgent.match(/iPad/i) != null;
      if (isiPad && this.version === 'pdf') {
        $('#savePdfTool').css({
          'opacity': '0.5',
          'pointer-events': 'none'
        });
      }
      if (this.version === 'page' || this.version === 'pdf') {
        this.setInitializeComponentView(data);
      }
    } else {
      this.area = '';
    }
  }

  @Input('printPdf')
  set printPdf(data: any) {
    if (data) {
      const previousTool = this.previousTool;
      this.drawingTool('savePdfTool');
      if (previousTool !== 'savePdfTool') {
        this.createSaveHtml(data.printData, 0);
      } else {
        this.printPdfCompleted.emit(true);
      }
    }
  }

  @Input('removeGreyBackground')
  set updateGreyBackground(data: boolean) {
    if (!data) {
      if (data !== this.drawingCanvasSelected) {
        this.removeGreyBackground(true, false);
        this.previousTool = null;
        $(`#${this.previousTool}`).removeClass(`${this.previousTool}_sel`);
      }
    }
  }

  @Input('resizeCanvas')
  set resizeCanvas(data: any) {
    if (data) {
      this.resizePdfCanvas(data.scale);
    }
  }

  @Output('printPdfCompleted') printPdfCompleted = new EventEmitter();
  @Output('saveChanges') saveChanges = new EventEmitter();
  @Output('toggleGreyBackground') toggleGreyBackground = new EventEmitter<boolean>();

  constructor(private sessionService: SessionService, private elService: elService) { }

  ngOnInit(): void {
    $('#saveTool').addClass('disabled');
  }

  ngOnDestroy(): void {
    this.highlighter = null;
    this.underliner = null;
    this.typer = null;
    $(document).off('mouseup touchend');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (this.version !== 'pdf') {
      $('.canvas-container').each((index: number, ele: any) => {
        $(`#${ele.id}`).css('left', $(`#${ele.id}`).prev().position().left + 'px');
      });
    }
  }

  /**
   * Initalizes the component view.
   * @param data the data to intitialize the view.
   */
  setInitializeComponentView(data: any) {
    this.canvasData = data.canvasData;
    if (this.version === 'pdf' && data.currentPageIndex > 1) {
      this.setCanvasDataInPool();
      this.setTypeToolDataInPool();
    }
    this.currentPageIndex = data.currentPageIndex;
    if ((data.previousIndex >= 0 && this.version === 'page') || (this.currentPageIndex === 1 && this.version === 'pdf')) {
      this.setPageData();
      this.setTypeToolDataInPool();
      this.drawingObject.length && this.drawingObject.each((index: number, ele: any) => {
        const parent = $(ele).parent();
        const container = $(parent).find('.canvas-container');
        if (container.length !== 0) {
          $(container).remove();
        } else {
          const canvas = $(parent).find('.drawCanvas');
          $(canvas).remove();
        }
        this.previousTool = null;
        this.removeGreyBackground(false, true);
      });
      this.setInputDataInPool(data.previousIndex);
    }
    this.htmlData = data.htmlData;
    this.resetInitialization();
    this.appendPageText();
    this.getAndSetInputData();
  }

  /**
   * Triggers on help button click.
   */
  helpClick() {
    let alertMessage = '';
    setTimeout(() => {
      $('.custom-alert-container').css('z-index', 100);
      $('.custom-alert .brTag').addClass("helpBRTag");
    }, 100);
    if (this.version === 'pdf') {
      alertMessage = '<ul style="list-style-type:circle"><li><b>Clear All Markup</b><br>The trash can icon will clear all your markup for the given page.</li><br class="brTag"><li><b>Type Tool</b><br>This tool allows you to type anywhere on the page. Enable the type tool icon, choose where to place your type, and begin typing. Enable the type tool icon again to create additional textboxes. You can drag the textbox around the page to move it. Drag the bottom right corner to resize as needed. Use the X button to delete a given textbox.</li><br class="brTag"><li><b>Colors</b><br>Choose a color from the color palette for use with the rectangle, circle, line, or marker tool.</li><br class="brTag"><li><b>Highlighter</b><br>Create a transparent rectangle to highlight a portion of the given page.</li><br class="brTag"><li><b>Circle</b><br>Create a circle by first placing the center and then dragging outward to increase the radius. Release to create the circle.</li><br class="brTag"><li><b>Line</b><br>Create a line by first choosing where to place one endpoint and then dragging to where to place the other endpoint. Release to create the line.</li><br class="brTag"><li><b>Marker</b><br>Draw anywhere on the page using the marker.</li><br class="brTag"><li><b>Eraser</b><br>Use the eraser tool to remove any individual markup in its entirety while retaining all other markups on the current page.</li></ul>';
    } else if (this.version === 'page') {
      alertMessage = '<ul style="list-style-type:circle"><li><b>Clear All Markup</b><br>This allows you to clear all your markup for the given page. Clearing all markup will still keep any text you type into pre-defined text boxes.</li><br><li><b>Text Annotation</b><br>When selected, this tool allows you to add your own annotations to existing selected text on the page. Annotations on text are indicated with a gray background.</li><br><li><b>Colors</b><br>Choose a color from the color palette for use with the highlighter, underline, rectangle, circle, line, or marker tool.</li><br><li><b>Text Highlight and Underline</b><br>Use these tools to markup existing text on the page. Select the icon to activate the tool and select again to deactivate.</li><br><li><b>Canvas Drawing Tools</b><br>At least one canvas-enabled element must be present on the given page for the canvas drawing tool icons (highlighter, circle, line, marker) to become active. Where available, select a drawing tool from the toolbar and NON-canvas elements on the page will become grayed out. Next, choose a canvas to draw on and you may then begin drawing anywhere within the red outline. At any time, you may switch to another drawing tool or re-select the currently selected tool to deactivate canvas drawing.</li><br><li><b>Eraser</b><br>Use the eraser tool to remove any individual markup in its entirety while retaining all other markups on the current page.</li></ul>';
    } else {
      alertMessage = '<b>Click </b> on Images to enable drawing tools like Circle,Rectangle,Line & Marker.<br><b> Click</b> on text to enable tools like Highlight,Underline,Type Tool,Remove.';
    }
    this.alertOptions = {
      type: 'alert',
      message: alertMessage,
      alertMaxWidthClassName: 'alert-md',
      confirm: () => {
        $('.custom-alert .brTag').removeClass("helpBRTag");
        $('.custom-alert-container').css('z-index', 0);
        this.alertOptions = null;
      }
    };
  }

  /**
   * Triggers on drawing tool buttons click.
   * @param type the type of drawing tool selected.
   * @returns 
   */
  drawingTool(type: string) {
    switch (type) {
      case 'highlighter':
        if (!this.previousHighlightColor && this.previousTool !== 'colorTool') {
          this.previousHighlightColor = this.selectedColor = 'yellow';
        } else if (this.previousHighlightColor) {
          this.selectedColor = this.previousHighlightColor;
        } else {
          this.selectedColor = 'yellow';
        }
        break;
      case 'underliner':
        if (!this.previousUnderlineColor && this.previousTool !== 'colorTool') {
          this.previousUnderlineColor = this.selectedColor = 'purple';
        } else if (this.previousUnderlineColor) {
          this.selectedColor = this.previousUnderlineColor;
        }
        break;
      case 'rectangleTool':
        if (!this.previousRectangleColor && this.previousTool !== 'colorTool') {
          this.previousRectangleColor = this.selectedColor = 'orange';
        } else if (this.previousRectangleColor) {
          this.selectedColor = this.previousRectangleColor;
        }
        break;
      case 'circleTool':
        if (!this.previousCircleColor && this.previousTool !== 'colorTool') {
          this.previousCircleColor = this.selectedColor = 'red';
        } else if (this.previousCircleColor) {
          this.selectedColor = this.previousCircleColor;
        }
        break;
      case 'lineTool':
        if (!this.previousLineColor && this.previousTool !== 'colorTool') {
          this.previousLineColor = this.selectedColor = 'blue';
        } else if (this.previousLineColor) {
          this.selectedColor = this.previousLineColor;
        }
        break;
      case 'markerTool':
        if (!this.previousMarkerColor && this.previousTool !== 'colorTool') {
          this.previousMarkerColor = this.selectedColor = 'black';
        } else if (this.previousMarkerColor) {
          this.selectedColor = this.previousMarkerColor;
        }
        break;
    }
    if ((['type', 'rectangleTool', 'circleTool', 'lineTool', 'markerTool'].includes(type)) && this.drawingObject.length === 0) {
      this.alertOptions = {
        type: 'confirm',
        message: 'The type, line, rectangle, circle, and marker tools are currently disabled because there are no canvas-enabled elements on the current page. These drawing tools will become selectable when there is a canvas-enabled element available to type or draw on, as part of certain lesson page activities.',
        confirm: () => { this.alertOptions = null; }
      };
    } else {
      this.showColorPallete = false;
      $(document).off('mouseup mousedown touchstart touchend');
      if (!this.previousTool || this.previousTool !== type) {
        this.previousTool = type;
        $('.tool').each((index: number, ele: any) => {
          $(ele).removeClass(`${ele.id}_sel`);
        });
        $(`#${type}`).addClass(`${type}_sel`);

        if (type === 'saveTool') {
          $(`#${type}`).removeClass(`${type}_sel`);
          this.previousTool = null;
          if (this.version !== 'pdf') {
            this.removeGreyBackground(false, true);
            this.saveInputTextArea();
          }
          this.saveDrawingHtml();
          this.isPageDirty = false;
          $('#saveTool').addClass('disabled');
          return;
        } else if (type === 'savePdfTool') {
          if (this.version === 'pdf') {
            this.saveAsPdf('pdf');
          } else {
            this.saveAsPdf('htmlContent');
            this.deactivateCanvas();
          }
          $('#saveTool').addClass('disabled');
          $(`#${type}`).removeClass(`${type}_sel`);
          this.detachMouseEvents(this.canvas);
          this.isPageDirty = false;
          return;
        }

        if (!['highlighter', 'underliner', 'pdfType', 'remove', 'eraser'].includes(type)) {
          if (this.version === 'pdf' || this.drawingCanvasSelected) {
            this.removePDFTypeToolHandler();
            if (type === 'type') {
              this.detachMouseEvents(this.canvas);
              this.addPDFTypeToolHandler();
            } else {
              this.drawShape($(`#${type}`).attr('data-type'));
            }
          } else {
            this.addGreyBackground(true);
          }
        } else if (type === 'remove') {
          this.removeSelection();
          this.previousTool = null;
          this.removeGreyBackground(true, true);
        } else if (type === 'eraser') {
          this.drawingToolErase();
        } else if (type === 'pdfType') {
          this.detachMouseEvents(this.canvas);
          this.addPDFTypeToolHandler();
        } else { // For Highlighter, Underliner and type tools. 
          this.deactivateCanvas();
          this.detachMouseEvents(this.canvas);
          this.removeGreyBackground(true, true);
          $(document).off('mousedown touchstart').on('mousedown touchstart', (event: any) => { this.documentMouseDown(event, type) });
          switch (type) {
            case 'highlighter':
              this.highlight();
              break;
            case 'underliner':
              this.underline();
              break;
            case 'type':
              this.type();
              break;
            default:
              break;
          }
        }
      } else {
        if (this.version === 'pdf') {
          if (this.previousTool === 'pdfType') {
            this.removePDFTypeToolHandler();
          } else {
            $('.upper-canvas').css('pointer-events', 'none');
          }
        } else {
          this.removeGreyBackground(true, true);
        }
        this.previousTool = null;
        $(`#${type}`).removeClass(`${type}_sel`);
      }
    }
  }

  /**
   * Triggers on color button click to display different color options.
   */
  colorSelect() {
    this.showColorPallete = !this.showColorPallete;
    this.colorPicked = false;
  }

  /**
   * Triggers on color option change. 
   * @param color the selected color.
   */
  colorOptionChange(color: string) {
    this.colorPicked = true;
    this.selectedColor = color;
    if(this.previousTool === 'markerTool'){
      this.canvas.freeDrawingBrush.color = color;
    }
  }

  /**
   * Resizes the canvas shapes with scale with the page zoom.
   * @param scale the zooming scale.
   */
  private resizePdfCanvas(scale: number) {
    if (this.canvas) {
      const scaleMultiplier = 612 * scale / this.canvas.getWidth();
      const objects = this.canvas.getObjects();
      for (let i in objects) {
        objects[i].scaleX = objects[i].scaleX * scaleMultiplier;
        objects[i].scaleY = objects[i].scaleY * scaleMultiplier;
        objects[i].left = objects[i].left * scaleMultiplier;
        objects[i].top = objects[i].top * scaleMultiplier;
        objects[i].setCoords();
      }
      this.canvas.setWidth(this.canvas.getWidth() * scaleMultiplier);
      this.canvas.setHeight(this.canvas.getHeight() * scaleMultiplier);
      this.canvas.renderAll();
      this.canvas.calcOffset();
      this.prevScale = scale;
    }
  }

  /**
   * Adds the grey background to the html page.
   */
  private addGreyBackground(emitEvent: boolean) {
    $('.greyedOutArea').css('height', $('#currentHorizontalPage').height() + 'px');
    $('.drawCanvas').css('z-index', 10);
    if (emitEvent) {
      this.toggleGreyBackground.emit(true);
    }
    $.each($('.drawCanvas'), (index: number, ele: any) => {
      if ($(ele).parent().hasClass('canvas-container')) {
        $(ele).parent().css('z-index', 10);
      }
    });
    this.drawingObject.each((index: number, ele: any) => {
      $(ele).css({
        'background-color': 'white',
        'z-index': 10,
        'position': 'relative'
      });
    });
    this.drawingCanvasSelected = true;
    $('.drawCanvas').css('pointer-events', 'auto');
    $('.upper-canvas').css('pointer-events', 'none');
    $(document).off('mouseup mousedown touchstart touchend');
  }

  /**
   * Removes the grey background from the html page.
   * @param canvasValue the canvas value is a flag to update the active canvas.
   */
  private removeGreyBackground(canvasValue: boolean, emitEvent: boolean) {
    $(document).off('mouseup mousedown touchstart touchend');
    if (emitEvent) {
      this.toggleGreyBackground.emit(false);
    }
    $('.upper-canvas').css('pointer-events', 'none');
    $('.drawCanvas').css('pointer-events', 'none');
    this.setCanvasDataInPool();
    if (canvasValue) {
      this.activeCanvas = null;
    }
    if (this.drawingCanvasSelected) {
      this.drawingCanvasSelected = false;
      $('.drawCanvas').css('z-index', '');
      $('.canvas-container').css('z-index', '');
      this.drawingObject.each((index: number, ele: any) => {
        $(ele).css({
          'background-color': '',
          'z-index': '',
          'position': '',
          'outline': ''
        });
      });
    }
  }

  /**
   * Resets the component properties and drawing tools.
   */
  private resetInitialization() {
    if (this.version === 'pdf') {
      $('.canvas-container').remove();
      $('#pdfTextAreaContainer').empty();
    }
    this.isPageDirty = false;
    this.rect = null;
    this.line = null;
    this.ellipse = null;
    this.typer = null;
    this.cssApplier = null;
    this.availableTypeTool = 0;
    this.pdfTypeToolCount = 0;
    this.canvas = null;
    this.previousTool = null;
    this.canvasPool = {};
    this.initialCanvasData = {};
    $('.tool').each((index: number, ele: any) => {
      $(ele).removeClass(`${ele.id}_sel`);
    });
    rangy.init();
    this.cssApplier = rangy.createClassApplier('highlight', {
      normalize: true,
      onElementCreate: (el: any) => {
        el.id = `Highlight${this.selectedColor}`;
      }
    });
    this.highlighter = rangy.createHighlighter(document, 'TextRange');
    this.highlighter.addClassApplier(this.cssApplier);
    const cssApplierUnderline = rangy.createClassApplier('underline', {
      normalize: true,
      onElementCreate: (el: any) => {
        el.id = `Underline${this.selectedColor}`;
      }
    });
    this.underliner = rangy.createHighlighter(document, 'TextRange');
    this.underliner.addClassApplier(cssApplierUnderline);
  }

  /**
   * Sets the pdf type tool data in seraialized json format.
   */
   private setTypeToolDataInPool() {
    if (typeof this.serializedPdfTypeToolPool[this.currentPageIndex] === 'undefined') {
      this.serializedPdfTypeToolPool[this.currentPageIndex] = {};
    }
    $('.pdfTypetool').each((index: number, ele: any) => {
      const tempObj: any = {};
      tempObj['height'] = $(ele).height();
      tempObj['width'] = $(ele).width();
      tempObj['top'] = $(ele).position().top;
      tempObj['left'] = $(ele).position().left;
      tempObj['canvas'] = $(ele).attr('data-canvas');
      tempObj['UserResponse'] = $(ele).find('textarea').val();
      this.serializedPdfTypeToolPool[this.currentPageIndex][`textarea${index + 1}`] = JSON.stringify(tempObj);
    });
  }

  /**
   * Sets the input data into the pool.
   */
  private setInputDataInPool(pageIndex: number) {
    if (this.textAreaInput && this.textAreaInput.length > 0) {
      this.textAreaInput.each((index: number, ele: any) => {
        if (this.serializedInputPool) {
          this.serializedInputPool[pageIndex][ele.id] = $(ele).val();
        }
      });
    }
  }

  /**
   * Sets the view with page data.
   */
  private setPageData() {
    if (typeof this.serializedCanvasPool === 'undefined') {
      this.serializedCanvasPool = this.getDrawing() || {};
    }
    if (typeof this.serializedCanvasPool[this.currentPageIndex] === 'undefined') {
      this.serializedCanvasPool[this.currentPageIndex] = {};
    }
    if (typeof this.serializedPdfTypeToolPool === 'undefined') {
      this.serializedPdfTypeToolPool = this.getPdfTypeTools() || {};
    }
    if (typeof this.serializedPdfTypeToolPool[this.currentPageIndex] === 'undefined') {
      this.serializedPdfTypeToolPool[this.currentPageIndex] = {};
    }
    if (typeof this.serializedInputPool === 'undefined') {
      this.serializedInputPool = {};
    }
    if (typeof this.serializedInputPool[this.currentPageIndex] === 'undefined') {
      this.serializedInputPool[this.currentPageIndex] = {};
    }
    this.availableTypeTool = $('.typeToolSelection').length;
  }

  /**
   * Appends the canvas to the page. 
   */
  private appendPageText() {
    if (this.version === 'pdf') {
      this.createDynamicTextArea();
      const newCanvas = document.createElement('canvas');
      newCanvas.id = 'can1';
      newCanvas.setAttribute('class', 'drawCanvas');
      newCanvas.width = 734;
      newCanvas.height = 950;
      $(`#${this.area}`).append(newCanvas);
      this.getCanvas();
      $('.canvas-container').css({
        'left': '0px',
        'right': '0px',
        'margin': 'auto'
      });
      return;
    }
    this.drawingObject = $(`#${this.area}`).find('[canvas = true]');
    this.drawingObject.each((index: number, ele: any) => {
      const imgWidth = $(ele).width() || 1;
      const pageWidth = ($('#htmlDisplayArea').width() || 1) * 90 / 100;
      if (imgWidth > (pageWidth - 25)) {
        $(ele).css('width', `${(pageWidth - 25)}px`);
      }
      if (document.getElementById(`can${(index + 1)}`) === null) {
        const newCanvas: any = document.createElement('canvas');
        newCanvas.id = 'can' + (index + 1);
        newCanvas.setAttribute('class', 'drawCanvas');
        newCanvas.width = $(ele).width();
        newCanvas.height = $(ele).height();
        newCanvas.style.left = $(ele).position().left + 'px';
        newCanvas.style.top = $(ele).position().top + 'px';
        $(newCanvas).insertAfter($(ele));
      }
    });
    this.getCanvas();
    this.createDynamicTextArea();
  }

  /**
   * Gets the canvas details including the fabric canvas appending to the page and saves the active canvas information into canvas pool.
   */
  private getCanvas() {
    if (!this.serializedPdfTypeToolPool[this.currentPageIndex]) {
      this.serializedPdfTypeToolPool[this.currentPageIndex] = {};
    }
    if (this.version === 'pdf') {
      this.activeCanvas = 'can1';
      this.canvas = new fabric.Canvas('can1', {
        selection: false
      });
      if (this.serializedCanvasPool[this.currentPageIndex]) {
        this.getCanvasDatafromPool(null, null);
      } else {
        this.serializedCanvasPool[this.currentPageIndex] = {};
      }
      fabric.Object.prototype.selectable = false;
      if (typeof this.canvasPool[this.currentPageIndex] === 'undefined') {
        this.canvasPool[this.currentPageIndex] = {};
      }
      this.canvasPool[this.currentPageIndex]['can1'] = this.canvas;
      return;
    }

    if (this.drawingObject.length === 0) {
      $('.drawTool').addClass('disabledDrawTool');
    } else {
      $('.drawTool').removeClass('disabledDrawTool');
    }
    $.each($('.drawCanvas'), (index: number, ele: any) => {
      if ($(ele).parent().hasClass('canvas-container')) {
        $(ele).parent().css('left', $(ele).parent().prev().position().left + 'px').css('top', $(ele).parent().prev().position().top + 'px');
      }
      this.saveInitialCanvasData(ele.id);
    });
    if (typeof this.canvasPool[this.currentPageIndex] === 'undefined') {
      this.canvasPool[this.currentPageIndex] = {};
    }
    this.onCanvasClick();
    if (this.serializedCanvasPool[this.currentPageIndex]) {
      for (let key in this.serializedCanvasPool[this.currentPageIndex]) {
        this.canvasPool[this.currentPageIndex][key] = new fabric.Canvas(key, {
          selection: false
        });
        fabric.Object.prototype.selectable = false;
        this.canvas = this.canvasPool[this.currentPageIndex][key];
        this.activeCanvas = key;
        $(`#${key}`).parent().css({
          'left': $('#' + key).parent().prev().position().left + 'px',
          'top': $('#' + key).parent().prev().position().top + ($('.htmlWrapper').scrollTop() || 0) + 'px'
        });
        this.getCanvasDatafromPool(null, null);
        $(`#${this.activeCanvas}`).next().css('pointer-events', 'none');
        this.activeCanvas = null;
      }
    }
  }

  /**
   * Gets the different shapes drawn over the canvas information of the current page.
   */
  private getDrawing() {
    if (this.canvasData && this.canvasData['canvasData'] && this.canvasData['canvasData'][this.currentPageIndex]) {
      return JSON.parse(this.canvasData['canvasData']);
    }
    return undefined;
  }

  /**
   * Gets the pdf type tools used on the canvas for drawing shapes from the stored details of the current page.
   */
  private getPdfTypeTools() {
    if (this.canvasData && this.canvasData['typeToolData']) {
      return JSON.parse(this.canvasData['typeToolData']);
    }
    return undefined;
  }

  /**
   * Gets the text area group data of all the pdf pages. 
   */
  private getAndSetInputData() {
    this.textAreaInput = $('#currentHorizontalPage').find('input, textarea').not('.typeToolInput');
    if (this.textAreaInput.length) {
      if (Object.keys(this.serializedInputPool[this.currentPageIndex]).length === 0) {
        const myDayDetails = this.sessionService.myDayViewDetails;
        const courseDetails = this.sessionService.courseDetails;
        const launcherDetails = this.sessionService.launcherDetails;
        this.getInputText(launcherDetails.userId, courseDetails.courseId, myDayDetails.pageId, 0);
      } else {
        this.textAreaInput.each((index: number, ele: any) => {
          $(`#${ele.id}`).val(this.serializedInputPool[this.currentPageIndex][ele.id]);
          $(`#${ele.id}`).on('input', (e: any) => {
            if (!this.isPageDirty) {
              $('#saveTool').removeClass('disabled');
              this.isPageDirty = true;
            }
            this.isSaveRequired[this.currentPageIndex] = true;
          });
        });
      }
    }
    if (this.isSaveRequired[this.currentPageIndex]) {
      if (!this.isPageDirty) {
        $('#saveTool').removeClass('disabled');
        this.isPageDirty = true;
      }
    }
  }

  /**
   * Gets the text area data using page id.
   * @param userId the user id.
   * @param courseId the course id.
   * @param pageId the page id.
   * @param index the index.
   */
  private getInputText(userId: number, courseId: number, pageId: number, index: number) {
    if (this.textAreaInput.length) {
      const elementId = this.textAreaInput[index].id;
      this.elService.getInput(userId, courseId, pageId, elementId).subscribe((res: any) => {
        if (res) {
          this.userInputId = res.userInput_id;
          if (res.data) {
            $(`#${elementId}`).val(res.data);
          }
          $(`#${elementId}`).on('input', (event: any) => {
            if (!this.isPageDirty) {
              $('#saveTool').removeClass('disabled');
              this.isPageDirty = true;
            }
            this.isSaveRequired[this.currentPageIndex] = true;
          });
          if (this.textAreaInput.length === index + 1) {
            this.setInputDataInPool(this.currentPageIndex);
          } else {
            this.getInputText(userId, courseId, pageId, index + 1);
          }
        }
      });
    }
  }

  /**
   * Creates the text area group from the stored canvas details.
   */
  private createDynamicTextArea() {
    if (this.serializedPdfTypeToolPool[this.currentPageIndex]) {
      for (let key in this.serializedPdfTypeToolPool[this.currentPageIndex]) {
        const pdfTypetool = JSON.parse(this.serializedPdfTypeToolPool[this.currentPageIndex][key]);
        if (this.version === 'pdf' && $(`#${key}`).parent()) { $(`#${key}`).parent().remove(); }
        setTimeout(() => {
          this.createTextArea(pdfTypetool.left, pdfTypetool.top, pdfTypetool.width, pdfTypetool.height, pdfTypetool.canvas, pdfTypetool.UserResponse);
        }, 100);
      }
    }
  }

  /**
   * Saves the initial canvas details.
   * @param id the canvas id.
   */
  private saveInitialCanvasData(id: string) {
    if (typeof this.initialCanvasData[this.currentPageIndex] === 'undefined') {
      this.initialCanvasData[this.currentPageIndex] = {};
    }
    this.initialCanvasData[this.currentPageIndex][id] = $(`#${id}`).parent().html();
  }

  /**
   * Gets the canvas details from canvas pool using page index and canvas id.
   * @param pageIndex the page index.
   * @param canId the canvas id.
   */
  private getCanvasDatafromPool(pageIndex: any, canId: any) {
    if (!pageIndex && !canId) {
      this.canvas.loadFromJSON(this.serializedCanvasPool[this.currentPageIndex][this.activeCanvas]);
    } else {
      this.canvas = new fabric.Canvas(`canRender${canId}`, {
        selection: false
      });
      this.canvas.loadFromJSON(this.serializedCanvasPool[pageIndex][`can${canId}`]);
      this.activeCanvas = null;
    }
    this.canvas.selection = false;
    this.canvas.renderAll();
  }

  /**
   * Sets the canvas data into the canvas pool.
   */
  private setCanvasDataInPool() {
    if (this.activeCanvas === null && this.version === 'pdf') {
      this.activeCanvas = 'can1';
    }
    if (this.activeCanvas) {
      this.serializedCanvasPool[this.currentPageIndex][this.activeCanvas] = JSON.stringify(this.canvas);
      this.activeCanvas = null;
    }
  }

  /**
   * Triggers after loading the default canvas details and update the shapes over the current page active canvas from the earlier stored canvas details.
   */
  private onCanvasClick() {
    $('.drawCanvas').css('pointer-events', 'none');
    $('.drawCanvas').off('mousedown').on('mousedown', (event: any) => {
      event.stopPropagation();
      const id = event.target.id;
      const canvasCheck = $(event.target).hasClass('upper-canvas');
      const canvasContainerLeft = event.target.style.left;
      const canvasContainerTop = event.target.style.top;
      $(document).off('mouseup mousedown touchstart touchend');
      this.drawingObject.each((index: number) => {
        $(`#${id}`).css('outline', 'none');
      });
      if ($(`#${id}`).parent().hasClass('canvas-container')) {
        $(`#${id}`).parent().prev().css('outline', '2px solid red');
      } else {
        $(`#${id}`).prev().css('outline', '2px solid red');
      }
      $(`#${id}`).next().css('pointer-events', 'auto');
      if (this.activeCanvas) {
        $(`#${this.activeCanvas}`).next().css('pointer-events', 'none');
        this.setCanvasDataInPool();
      }
      if (typeof this.canvasPool[this.currentPageIndex] !== 'undefined' && this.canvasPool[this.currentPageIndex][id]) {
        this.canvas = this.canvasPool[this.currentPageIndex][id];
        this.activeCanvas = id;
      } else if (this.activeCanvas !== id) {
        this.activeCanvas = id;
        this.canvas = new fabric.Canvas(id, { selection: false });
        if (!canvasCheck) {
          $(`#${id}`).parent().css({ 'left': canvasContainerLeft, 'top': canvasContainerTop, 'z-index': 10 });
        }
        fabric.Object.prototype.selectable = false;
        this.canvasPool[this.currentPageIndex][id] = this.canvas;
      } else {
        this.activeCanvas = null;
      }
      if (this.activeCanvas && this.version !== 'pdf') {
        if (this.previousTool === 'type') {
          this.detachMouseEvents(this.canvas);
          this.addPDFTypeToolHandler();
        } else {
          this.drawShape($(`#${this.previousTool}`).attr('data-type'));
        }
      }
    });
  }

  /**
   * Creates the text box over the pdf canvas with shared parameters.
   * @param xPos the x position.
   * @param yPos the y position.
   * @param boxWidth the width of the textbox.
   * @param boxHeight the height of the textbox.
   * @param activeCanvas the active canvas.
   * @param userInput the user input.
   */
  private createTextArea(xPos: number, yPos: number, boxWidth: any, boxHeight: any, activeCanvas: any, userInput: any) {
    const textInputStr = $(`<div class="pdfTypetool" data-canvas='${activeCanvas}'><textarea class="typeToolInput" id="textarea${(++this.pdfTypeToolCount)}"></textarea><span class="typeToolDeleteBtn">x</span></div>`)
      .width(boxWidth).height(boxHeight).draggable({
        cancel: 'text',
        containment: $(`#${this.activeCanvas}`)
      }).resizable({
        containment: $(`#${this.activeCanvas}`)
      }).offset({
        left: xPos,
        top: yPos
      });
    if (this.version === 'pdf') {
      textInputStr.appendTo('#pdfTextAreaContainer');
    } else {
      textInputStr.appendTo($(`#${activeCanvas}`).parent());
    }
    if (userInput) {
      $(`#textarea${this.pdfTypeToolCount}`).val(userInput);
      const elm = $(`#textarea${this.pdfTypeToolCount}`).get(0);
      elm.selectionStart = elm.selectionEnd = elm.value.length;
      elm.focus();
      this.focusAndCloseBtnHandler();
    }

    setTimeout(() => {
      $(`#textarea${this.pdfTypeToolCount}`).focus();
      this.focusAndCloseBtnHandler();
    });
  }

  /**
   * Erases the different shapes drawn on the pdf canvas.
   */
  private drawingToolErase() {
    if (this.version !== 'pdf') {
      this.removeGreyBackground(true, true);
      $(document).off('mousedown touchstart').on('mousedown touchstart', (event: any) => this.onDocumentMouseDown(event));
    }
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    this.setTypeToolDataInPool();
    $('.canvas-container').remove();
    if (this.canvasPool[this.currentPageIndex]) {
      for (let key in this.canvasPool[this.currentPageIndex]) {
        this.canvas = this.canvasPool[this.currentPageIndex][key];
        this.activeCanvas = key;
        this.setCanvasDataInPool();
        this.getCanvasDatafromPool(null, null);
      }
      this.appendPageText();
    }
    $('.drawCanvas').css('pointer-events', 'auto');
    fabric.Object.prototype.set({
      selectable: true,
      transparentCorners: true,
      borderColor: 'red',
      cornerColor: 'transparent',
      rotatingPointOffset: 0
    });
    if (this.canvas && this.canvasPool[this.currentPageIndex]) {
      for (let key in this.canvasPool[this.currentPageIndex]) {
        this.canvas = this.canvasPool[this.currentPageIndex][key];
        this.detachMouseEvents(this.canvas);
        this.canvas.on('mouse:down', (event: any) => this.onCanvasMouseDown(event));
        this.canvas.renderAll();
      }
    }
  }

  /**
   * Adds the handler to pdf type tool for mouse down and touch start.
   */
  private addPDFTypeToolHandler() {
    $('.canvas-container').off('mousedown touchstart').on('mousedown touchstart', (event: any) => this.onPDFCanvasClick(event));
  }

  /**
   * Appends the handlers and properties for drwaing the shape using type.
   * @param type the type of the drawing shape.
   */
  private drawShape(type: any) {
    this.detachMouseEvents(this.canvas);
    const drawingShapeObject: any = {
      isDown: false,
      origX: 0,
      origY: 0
    };
    this.canvas.isDrawingMode = false;
    if (type === 'marker') {
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.color = this.selectedColor;
      this.canvas.freeDrawingBrush.width = 5;
      this.previousMarkerColor = this.selectedColor;
      this.canvas.on('mouse:down', () => {this.onMarkerMouseDown()});
      this.canvas.on('mouse:up', () => {this.onMarkerMouseUp()});
      return;
    }
    this.canvas.on('mouse:down', (object: any) => { this.onOtherPdfDrawingToolMouseDown(object, drawingShapeObject, type); });
    this.canvas.on('touch:start', (object: any) => { this.onOtherPdfDrawingToolMouseDown(object, drawingShapeObject, type); });
    this.canvas.on('mouse:move', (object: any) => { this.onOtherPdfDrawingToolMouseMove(object, drawingShapeObject, type); });
    this.canvas.on('touch:move', (object: any) => { this.onOtherPdfDrawingToolMouseMove(object, drawingShapeObject, type); });
    this.canvas.on('mouse:up', (object: any) => { this.onOtherPdfDrawingToolMouseUp(object, drawingShapeObject, type); });
    this.canvas.on('touch:end', (object: any) => { this.onOtherPdfDrawingToolMouseUp(object, drawingShapeObject, type); });
  }

  /**
   * Highlights the text background with user selected color or default color of the tool.
   */
  private highlight() {
    if (this.newHighlightColor) {
      $('#sel_color').css('background', this.newHighlightColor);
    }
    const selection = rangy.getSelection();
    this.highlighter.highlightSelection('highlight', selection);
    selection.removeAllRanges();
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    this.previousHighlightColor = this.newHighlightColor;
    if (this.previousTool === 'colorTool') {
      this.previousTool = null;
    }
  }

  /**
   * Underlines the selected text with default color or user selected color.
   */
  private underline() {
    if (this.selectedColor !== '') {
      $('#sel_color').css('background', this.selectedColor);
    }
    const selection = rangy.getSelection();
    this.underliner.highlightSelection("underline", selection);
    selection.removeAllRanges();
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    this.previousUnderlineColor = this.selectedColor;
    if (this.previousTool === 'colorTool') {
      this.previousTool = null;
    }
  }

  /**
   * Handles the user entered text with default color or user selected color in html view.
   */
  private type() {
    var selection = rangy.getSelection();
    let cssApplierType = rangy.createClassApplier('typeToolSelection', {
      normalize: true
    });
    this.typer = rangy.createHighlighter(document, 'TextRange');
    this.typer.addClassApplier(cssApplierType);
    this.typer.highlightSelection('typeToolSelection', selection);
    cssApplierType = rangy.createClassApplier(`t_${(++this.availableTypeTool)}`, {
      normalize: true
    });
    this.typer = rangy.createHighlighter(document, 'TextRange');
    this.typer.addClassApplier(cssApplierType);
    this.typer.highlightSelection(`t_${this.availableTypeTool}`, selection);
    selection.removeAllRanges();
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    this.addTypeTool();
  }

  /**
   * Initializes the shape over the canvas with user selected position.
   * @param type the type of shape.
   * @param origX the initial x position.
   * @param origY the initial y position.
   * @param pointer the pointer object.
   */
  private initializeShape(type: any, origX: any, origY: any, pointer: any) {
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    if (this.selectedColor) {
      $('#sel_color').css('background', this.selectedColor);
    }
    if (this.previousTool === 'colorTool') {
      this.previousTool = null;
    }
    switch (type) {
      case 'circle':
        this.ellipse = new fabric.Ellipse({
          left: origX,
          top: origY,
          originX: 'left',
          originY: 'top',
          rx: pointer.x - origX,
          ry: pointer.y - origY,
          angle: 0,
          strokeWidth: 2,
          stroke: this.selectedColor,
          fill: 'transparent',
          selectable: false
        });
        this.canvas.add(this.ellipse);
        this.previousCircleColor = this.selectedColor;
        return;
      case 'rectangle':
        this.rect = new fabric.Rect({
          left: origX,
          top: origY,
          originX: 'left',
          originY: 'top',
          width: 1,
          height: 1,
          angle: 0,
          fill: this.selectedColor,
          opacity: 0.5,
          transparentCorners: false,
          selectable: false
        });
        this.canvas.add(this.rect);
        this.previousRectangleColor = this.selectedColor;
        return;
      case 'line':
        const points = [origX, origY, origX, origY];
        this.line = new fabric.Line(points, {
          strokeWidth: 5,
          fill: this.selectedColor,
          stroke: this.selectedColor,
          originX: 'center',
          originY: 'center',
          selectable: false
        });
        this.canvas.add(this.line);
        this.previousLineColor = this.selectedColor;
        return;
      default:
        break;
    }
  }

  /**
   * Updates the shape over the canvas on user dragging the mouse.
   * @param type the type of shape.
   * @param origX the initial x position.
   * @param origY the intial y position.
   * @param pointer the pointer object.
   */
  private updateShape(type: any, origX: any, origY: any, pointer: any) {
    switch (type) {
      case 'circle':
        let rx = Math.abs(origX - pointer.x) / 2;
        let ry = Math.abs(origY - pointer.y) / 2;
        this.ellipse.set({
          rx: rx > this.ellipse.strokeWidth ? (rx - this.ellipse.strokeWidth / 2) : rx,
          ry: ry > this.ellipse.strokeWidth ? (ry - this.ellipse.strokeWidth / 2) : ry,
          originX: origX > pointer.x ? 'right' : 'left',
          originY: origY > pointer.y ? 'bottom' : 'top'
        });
        this.canvas.renderAll();
        return;
      case 'rectangle':
        this.rect.set({
          left: origX > pointer.x ? Math.abs(pointer.x) : this.rect.get('left'),
          top: origY > pointer.y ? Math.abs(pointer.y) : this.rect.get('top'),
          width: Math.abs(origX - pointer.x),
          height: Math.abs(origY - pointer.y)
        });
        this.canvas.renderAll();
        return;
      case 'line':
        this.line.set({
          x2: pointer.x,
          y2: pointer.y
        });
        this.canvas.renderAll();
        return;
    }
  }

  /**
   * Appends the type tool for html view.
   */
  private addTypeTool() {
    const typeElem = $(`t_${this.availableTypeTool}`)[0];
    if (!typeElem) {
      return;
    }
    let left = '', top = '';
    const elemLeft = $(typeElem).position().left;
    const contWidth = $('#htmlContent').width();
    const elemWidth = $(typeElem).width();
    if (elemLeft + 200 > contWidth) {
      left = (elemWidth - 200) + 'px';
    } else {
      left = '0px';
    }
    if ($(typeElem).position().top - 30 < 50) {
      top = '20px';
    } else {
      top = '-30px';
    }
    $(typeElem).append(`<span class="typeWindow" style="left:${left};top:${top};word-wrap:break-word;"><span contenteditable="true" class="editableWindow"></span><span class="closeTypeWindow">&#8211;</span></span>`);
    const editable = $(typeElem).find(".editableWindow")[0];
    $(editable).trigger('focus');
    let editMode = true;
    $(editable).off('mouseover click mouseout').on('mouseover click mouseout', (event: any) => {
      if (event.type === 'click') {
        editMode = true;
      } else if (event.type === 'mouseout' && !editMode) {
        $(event.target).parent().hide();
        return;
      }
      $(event.target).show();
    });
  }

  /**
   * Handles the text area close button click event.
   */
  private focusAndCloseBtnHandler() {
    $(`#textarea${this.pdfTypeToolCount}`).off('mousedown touchstart').on('mousedown touchstart', (event: any) => {
      $(event.target).focus();
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    });
    $(`#textarea${this.pdfTypeToolCount}`).next().off('mousedown touchstart').on('mousedown touchstart', (event: any) => {
      $(event.target).parent().remove();
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
      if (!$('.pdfTypetool').length) {
        this.serializedPdfTypeToolPool[this.currentPageIndex] = undefined;
      }
      this.setTypeToolDataInPool();
    });
  }

  /**
   * Triggers the respective event handlers of respective tool on mouse down after tool selection.
   * @param event the event.
   * @param type the type.
   * @returns 
   */
  private documentMouseDown(event: any, type: string) {
    if (event.target.className === 'editableWindow' || event.target.id === 'sel_color') {
      return;
    }
    switch (type) {
      case 'highlighter':
        this.highlight();
        break;
      case 'underliner':
        this.underline();
        break;
      case 'type':
        this.type();
        break;
      default:
        break;
    }
  }

  /**
   * Removes the respective event activity on html page.
   * @param event the event.
   */
  private onDocumentMouseDown(event: any) {
    if (event.target.id.substring(-9, 9) === 'Highlight') {
      $(event.target).removeAttr('id');
      const highlight = this.highlighter.getHighlightForElement(event.target);
      if (highlight) {
        this.highlighter.removeHighlights([highlight]);
      }
    } else if (event.target.id.substring(-9, 9) === 'Underline') {
      $(event.target).removeAttr('id');
      const underline = this.underliner.getHighlightForElement(event.target);
      if (underline) {
        this.underliner.removeHighlights([underline]);
      }
    } else if ($(event.target).hasClass('typeToolSelection')) {
      $(event.target).find('.typeWindow').remove();
      $(event.target).contents().unwrap();
      const type = this.typer.getHighlightForElement(event.target);
      if (type) {
        this.typer.removeHighlights([type]);
      }
    }
  }

  /**
   * Triggers this mouse down event to clear the single shape from the canvas which is activated from erase tool selection. 
   * @param event the event.
   */
  private onCanvasMouseDown(event: any) {
    if (!event.target) {
      return;
    }
    this.activeCanvas = event.target.canvas.lowerCanvasEl.id;
    this.canvas = event.target.canvas;
    event.target.canvas.remove(event.target.canvas.getActiveObject());
    this.canvas.renderAll();
    this.isPageDirty = true;
    this.setCanvasDataInPool();
  }

  /**
   * Triggers this event to create text box over the canvas on mouse down at speciic position. 
   * @param event the event.
   */
  private onPDFCanvasClick(event: any) {
    if (event.currentTarget.className === 'canvas-container') {
      let defaultWidth = 200,
        defaultHeight = 26,
        mousePosX: any = null,
        mousePosY: any = null;
      if (this.version === 'pdf') {
        mousePosX = event.originalEvent.layerX + event.currentTarget.offsetLeft;
        mousePosY = event.originalEvent.layerY + event.currentTarget.offsetTop;
      } else {
        mousePosX = event.offsetX;
        mousePosY = event.offsetY;
      }
      this.createTextArea(mousePosX, mousePosY, defaultWidth, defaultHeight, this.activeCanvas, null);
      this.removePDFTypeToolHandler();
      $(`#${this.previousTool}`).removeClass(`${this.previousTool}_sel`);
      this.previousTool = null;
      if (this.version !== 'pdf') {
        this.removeGreyBackground(true, true);
      }
      if (!this.isPageDirty) {
        $('#saveTool').removeClass('disabled');
        this.isPageDirty = true;
      }
    } else if ($(event.currentTarget)[0].tagName === 'TEXTAREA') {
      $('#' + $(event.currentTarget)[0].id).trigger('focus');
    }
  }

  /**
   * Triggers this event on mouse down over canvas after marker tool selection.
   */
  private onMarkerMouseDown() {
    if (!this.isPageDirty) {
      $('#saveTool').removeClass('disabled');
      this.isPageDirty = true;
    }
    if (this.selectedColor) {
      $('#sel_color').css('background', this.selectedColor);
    }
    this.previousMarkerColor = this.selectedColor;
    if (this.previousTool === 'colorTool') {
      this.previousTool = null;
    }
    if (this.area === 'pdfContainer') {
      $('#pdfContainerScroll').addClass('ps-container');
    } else {
      $('#htmlContent').addClass('ps-container');
    }
  }

  /**
   * Triggers this event on mouse up over canvas after marker tool selection.
   */
  private onMarkerMouseUp() {
    if (this.area === 'pdfContainer') {
      $('#pdfContainerScroll').addClass('ps-container');
    }
  }

  /**
   * Triggers this event on mouse down over canvas after selecting other than marker tool.
   * @param object the object with event details.
   * @param drawingShapeObject the drawing shape position details.
   * @param type the type of the shape to draw.
   */
  private onOtherPdfDrawingToolMouseDown(object: any, drawingShapeObject: any, type: any) {
    if (this.area === 'pdfContainer') {
      $('#pdfContainerScroll').addClass('ps-container');
    } else {
      $('#htmlContent').addClass('ps-container');
    }
    const pointer = this.canvas.getPointer(object.e);
    drawingShapeObject.isDown = true;
    drawingShapeObject.origX = pointer.x;
    drawingShapeObject.origY = pointer.y;
    this.initializeShape(type, drawingShapeObject.origX, drawingShapeObject.origY, pointer);
  }

   /**
   * Triggers this event on mouse move to draw shape over canvas after selecting other than marker tool.
   * @param object the object with event details.
   * @param drawingShapeObject the drawing shape position details.
   * @param type the type of the shape to draw.
   */
  private onOtherPdfDrawingToolMouseMove(object: any, drawingShapeObject: any, type: any) {
    object.e.preventDefault();
    if (!drawingShapeObject.isDown) {
      return;
    }
    const pointer = this.canvas.getPointer(object.e);
    this.updateShape(type, drawingShapeObject.origX, drawingShapeObject.origY, pointer);
  }

  /**
   * Triggers this event on mouse up to stop drawing the shape over canvas after selecting other than marker tool.
   * @param object the object with event details.
   * @param drawingShapeObject the drawing shape position details.
   * @param type the type of the shape to draw.
   */
  private onOtherPdfDrawingToolMouseUp(object: any, drawingShapeObject: any, type: any) {
    drawingShapeObject.isDown = false;
    if (this.area === 'pdfContainer') {
      $('#pdfContainerScroll').addClass('ps-container');
    }
  }

  /**
   * Removes the all markup for the active slide.
   */
   private removeSelection() {
    setTimeout(() => {
      $('.custom-alert-container').css('z-index', 100);
    }, 100);
    this.alertOptions = {
      type: 'confirm',
      message: 'Are you sure you wish to clear all markup for this slide?',
      dismiss: () => {
        $('#remove').removeClass('remove_sel');
        this.previousTool = null;
        $('.custom-alert-container').css('z-index', 0);
        this.alertOptions = null;
      },
      confirm: () => {
        $('.highlight, .underline').removeAttr('id');
        this.highlighter.removeAllHighlights();
        this.underliner.removeAllHighlights();
        if (this.version === 'pdf') {
          $('#pdfTextAreaContainer').empty();
        } else {
          $('.pdfTypetool').remove();
        }
        if (!$('.pdfTypetool').length) {
          this.serializedPdfTypeToolPool[this.currentPageIndex] = undefined;
        }
        this.setTypeToolDataInPool();
        $('#remove').removeClass('remove_sel');
        if (typeof this.serializedCanvasPool[this.currentPageIndex] !== 'undefined') {
          this.serializedCanvasPool[this.currentPageIndex] = {};
        }
        this.canvas = null;
        this.activeCanvas = null;
        if (typeof this.canvasPool[this.currentPageIndex] !== 'undefined') {
          this.canvasPool[this.currentPageIndex] = undefined;
        }
        $('.canvas-container').remove();
        this.appendPageText();
        this.saveDrawingHtml();
        $('#saveTool').addClass('disabled');
        this.isPageDirty = false;
        this.alertOptions = null;
        $('.custom-alert-container').css('z-index', 0);
      }
    };
  }


  /**
   * Removes all the active pdf type tool handlers over the canvas container.
   */
  private removePDFTypeToolHandler() {
    $('.canvas-container').off('mousedown touchstart');
  }

  /**
   * Detaches all mouse events over fabric canvas instance.
   * @param fabricCanvas the fabric canvas instance.
   * @returns 
   */
  private detachMouseEvents(fabricCanvas: any) {
    if (!fabricCanvas) {
      return;
    }
    if (typeof fabricCanvas.__eventListeners !== 'undefined') {
      fabricCanvas.__eventListeners['mouse:down'] = [];
      fabricCanvas.__eventListeners['mouse:move'] = [];
      fabricCanvas.__eventListeners['mouse:up'] = [];
      fabricCanvas.__eventListeners['touch:start'] = [];
      fabricCanvas.__eventListeners['touch:move'] = [];
      fabricCanvas.__eventListeners['touch:end'] = [];
      fabricCanvas.isDrawingMode = false;
    }
  }

  /**
   * Deactivates the canvas.
   */
  private deactivateCanvas() {
    $('.upper-canvas').css('pointer-events', 'none');
    this.activeCanvas = null;
  }

  /**
   * Saves the pdf canvas information for html markup data. 
   * @param type the type of the page (html/pdf).
   */
  private saveAsPdf(type: string) {
    if (type !== 'pdf') {
      if (this.canvas) {
        this.detachMouseEvents(this.canvas);
      }
      this.setCanvasDataInPool();
    }
  }

  /**
   * Creates the pdf pages data from html markup.
   * @param printData the printing data.
   * @param index the index.
   */
  private createSaveHtml(printData: any, index: number) {
    const element = document.createElement('div');
    element.className = 'printDoc';
    element.innerHTML = printData[index];
    element.style.width = '900px';
    document.getElementsByTagName('body')[0].appendChild(element);
    this.setInputDataInPool(this.currentPageIndex);
    const currentInputArea = $('.printDoc').find('input, textarea');
    if (currentInputArea.length !== 0) {
      currentInputArea.each((i: number, ele: any) => {
        if (this.serializedInputPool[index]) {
          if (ele.id) {
            $('.printDoc').find(`#${ele.id}`).text(this.serializedInputPool[index][ele.id]);
          }
        }
      });
    }
    this.getAllImagesFromPage(index, () => {
      this.htmlDataToCovertPDF += element.innerHTML;
      if (++index !== (printData.length)) {
        $(element).remove();
        this.createSaveHtml(printData, index);
      } else {
        element.innerHTML = this.htmlDataToCovertPDF;
        this.openDefaultPrintWindow(element);
        this.printPdfCompleted.emit(true);
        this.htmlDataToCovertPDF = '';
        return;
      }
    });
  }

  /**
   * Gets the all images from the page.
   * @param pageIndex the page index.
   * @param callback the callback.
   */
  private getAllImagesFromPage(pageIndex: number, callback: Function) {
    const images = $('.printDoc  img');
    this.convertImgToBase64URL(images, 0, pageIndex, () => {
      callback();
      return;
    });
  }

  /**
   * Converting the all images from the page to base64 url.
   * @param images the image set.
   * @param count the count.
   * @param pageIndex the page index.
   * @param callback the callback.
   */
  private convertImgToBase64URL(images: any[], count: number, pageIndex: number, callback: Function) {
    if (typeof this.serializedCanvasPool[pageIndex] === 'undefined' || images.length === 0) {
      callback();
      return;
    }
    if (typeof this.serializedCanvasPool[pageIndex][`can${(count + 1)}`]) {
      const canvas: any = document.createElement('CANVAS');
      let dataURL = '';
      canvas.id = `canRender${(count + 1)}`;
      canvas.width = $(images[count]).width();
      canvas.height = $(images[count]).height();
      canvas.style.left = $(images[count]).position().left + 'px';
      canvas.style.top = $(images[count]).position().top + 'px';
      $(canvas).insertAfter($(images[count]));
      var image = new Image();
      image.height = $(images[count]).height();
      image.width = $(images[count]).width();
      image.style.position = 'absolute';
      image.style.left = $(images[count]).position().left + 'px';
      this.getCanvasDatafromPool(pageIndex, (count + 1));
      dataURL = this.canvas.toDataURL();
      $(canvas).remove();
      this.canvas = undefined;
      image.src = dataURL;
      $(image).insertAfter($(images[count]));
    }
    count++;
    if (images.length == count) {
      callback();
      return;
    } else {
      this.convertImgToBase64URL(images, count, pageIndex, callback);
    }
  }

  /**
   * Opens the print window in other tab.
   * @param elem the html element contains pdf markup.
   */
  private openDefaultPrintWindow(elem: any) {
    $('.printDoc .canvas-container').remove();
    $('.printDoc canvas').remove();
    const myDayDetails = this.sessionService.myDayViewDetails;
    const courseDetails = this.sessionService.courseDetails;
    let pageInfo = `<h3>Lesson Name : ${myDayDetails.nameOfLesson}</h3>`;
    pageInfo += `<h3>Lesson Title : ${myDayDetails.titleOfLesson}</h3>`;
    pageInfo += `<h3>Course Name : ${courseDetails.courseName}</h3>`;
    pageInfo += `<h3>Task Id : ${myDayDetails.taskId}</h3>`;
    pageInfo += `<h3>Course Id : ${courseDetails.courseId}</h3>`;
    const win: any = window.open('', '', '');
    win.document.write(`<html><head><title>${myDayDetails.titleOfLesson}</title>`);
    win.document.write('<link rel="stylesheet" href="../../../../../assets/pdf-styles.css" type="text/css"/ media="screen, print" >');
    win.document.write('<link rel="stylesheet" href="../../../../../assets/bootstrap/dist/css/bootstrap.min.css" type="text/css"/ media="screen, print" >');
    win.document.write('</head><body onload="window.print()" style="width:900px;" class="printDoc">');
    win.document.write(pageInfo + elem.innerHTML);
    win.document.write('</body></html>');
    win.document.close();
    $(elem).remove();
  }

  /**
   * Saves the text area group.
   */
  private saveInputTextArea() {
    const myDayDetails = this.sessionService.myDayViewDetails;
    const courseDetails = this.sessionService.courseDetails;
    const launcherDetails = this.sessionService.launcherDetails;
    if (this.textAreaInput.length && !$('#saveTool').hasClass('disabled')) {
      $('#savingText span').addClass('savingAnimation');
      this.showSavingText = true;
      this.saveInputText(launcherDetails.userId, courseDetails.courseId, myDayDetails.pageId, 0);
      this.isSaveRequired[this.currentPageIndex] = false;
    }
  }

  /**
   * Saves the user entered data from textbox. 
   * @param userId the user id.
   * @param courseId the course id.
   * @param pageId the pge id.
   * @param count the count.
   */
  private saveInputText(userId: number, courseId: number, pageId: number, count: number) {
    const userResponse = $('#' + this.textAreaInput[count].id).val();
    if (userResponse) {
      this.elService.saveInput(userId, courseId, pageId, this.textAreaInput[count].id, this.userInputId, userResponse).subscribe((res: any) => {
        if (++count === this.textAreaInput.length) {
          this.savingTextAnimation();
        } else {
          this.saveInputText(userId, courseId, pageId, count);
        }
      });
    } else {
      if (++count === this.textAreaInput.length) {
        this.savingTextAnimation();
      } else {
        this.saveInputText(userId, courseId, pageId, count);
      }
    }
  }

  /**
   * Saves the mark up data.
   */
  private saveDrawingHtml() {
    this.previousTool = null;
    this.setCanvasDataInPool();
    this.setTypeToolDataInPool();
    if (this.version === 'pdf') {
      this.saveChanges.emit({ canvasPool: JSON.stringify(this.serializedCanvasPool), typeTool: JSON.stringify(this.serializedPdfTypeToolPool) });
      return;
    }
    let htmlContent = this.htmlData.join('<hr>');
    for (let key in this.canvasPool[this.currentPageIndex]) {
      const newHtml = $(`#${key}`).parent().parent().html();
      htmlContent = this.htmlData[this.currentPageIndex].replace(newHtml, this.initialCanvasData[this.currentPageIndex][key]);
    }
    this.saveChanges.emit({ htmlData: htmlContent, canvasPool: JSON.stringify(this.serializedCanvasPool), typeTool: JSON.stringify(this.serializedPdfTypeToolPool) });
  }

  /**
   * Display the animation after saving the textbox data.
   */
  private savingTextAnimation() {
    $('#savingText span').css('visibility', 'hidden').removeClass('savingAnimation');
    $('#savingText div').text('Saved.');
    setTimeout(() => {
      this.showSavingText = false;
      $('#savingText div').text('Saving');
      $('#savingText span').css('visibility', 'visible');
    }, 3000);
  }
}
