import { Injectable, Injector } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { CourseService } from "../course/course.service";

@Injectable()
export class elService extends BehaviorSubject<any> {
  private _courseService: CourseService | undefined;
  constructor(private injector: Injector) {
    super(null);
  }

  public get CourseService(): CourseService {
    if (!this._courseService) {
      this._courseService = this.injector.get(CourseService);
    }
    return this._courseService;
  }

  /**
   * Gets the user preferences.
   * @param userId The user id.
   */
  getPreferences(userId: any): Observable<any> {
    return this.CourseService.getPreferences(userId);
  }
  /**
   * saves the user preferences
   * @param userId the userId
   * @param themeData the user selected preferences.
   */
  savePreferences(userId: number, themeData: any): Observable<any> {
    return this.CourseService.savePreferences(userId, themeData);
  }

  /**
   * Gets the course year list of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseData(courseId: any, userId: any): Observable<any> {
    return this.CourseService.getCourseData(courseId, userId);
  }

  /**
   * Gets the course year data of the user.
   * @param schoolYearId The school year id.
   * @param userId The user id.
   */
  getCourseYear(schoolYearId: any, userId: any): Observable<any> {
    return this.CourseService.getCourseYear(schoolYearId, userId);
  }

  /**
   * Gets the course year data from local memory.
   */
  getCourseYearData(): Array<any> {
    return this.CourseService.getCourseYearData();
  }

  /**
   * Gets the course map details of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseMap(courseId: any, userId: any): Observable<any> {
    return this.CourseService.getCourseMap(courseId, userId);
  }

  /**
   * Gets the course grade details of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseGrades(courseId: any, userId: any): Observable<any> {
    return this.CourseService.getCourseGrades(courseId, userId);
  }

  /**
   * Gets the task data of the user.
   * @param courseId The course id.
   * @param lessonId The lesson id.
   * @param userId The user id.
   */
  getTaskList(courseId: any, lessonId: any, userId: any) {
    return this.CourseService.getTaskList(courseId, lessonId, userId);
  }

  /**
   * Gets the assessment details of the user
   * @param assessmentId The assessmen id.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getAssessment(assessmentId: string, courseId: any, userId: any) {
    return this.CourseService.getAssessment(assessmentId, courseId, userId);
  }

  /**
   * Sends the email to teacher.
   * @param courseId The course id.
   * @param emailBodyText The email body.
   * @param emailSubjectTxt The email subject.
   * @param userId The user id.
   */
  messageTeacher(courseId: any, emailBodyText: any, emailSubjectTxt: any, userId: any) {
    return this.CourseService.messageTeacher(courseId, emailBodyText, emailSubjectTxt, userId);
  }

  /**
   * Starts a particular task of the user.
   * @param courseId The course id.
   * @param taskId The task id.
   * @param userId The user id.
   */
  startTask(courseId: any, taskId: any, userId: any) {
    return this.CourseService.startTask(courseId, taskId, userId);
  }

  /**
   * Completes a particular task of the user
   * @param courseId The course id.
   * @param taskId The task id.
   * @param userId The user id.
   */
  completeTask(courseId: any, taskId: any, userId: any) {
    return this.CourseService.completeTask(courseId, taskId, userId);
  }

  /**
   * Saves the Notepad data.
   * @param course_id the course id
   * @param user_id  the user id
   * @param lesson_id  the lesson id
   * @param data  the notebook details
   * @returns 
   */
  saveNotePad(course_id: any, user_id: any, lesson_id: any, data: any) {
    return this.CourseService.saveNotePad(course_id, user_id, lesson_id, data)
  }

  /**
  * gets the notepad data.
  * @param course_id the course id
  * @param user_id  the user id
  * @param lesson_id  the lesson id
  * @returns 
  */
  getNotePad(course_id: any, user_id: any, lesson_id: any) {
    return this.CourseService.getNotePad(course_id, user_id, lesson_id)
  }

  /**
   * Starts the assessment.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @returns 
   */
  startAssessment(courseId: any, userId: any, assessmentId: any): Observable<any> {
    return this.CourseService.startAssessment(courseId, userId, assessmentId);
  }

  /**
   * Saves the assessment details.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @param questionId the question id.
   * @param userAnswer the user answer.
   * @param returnAnswer the return answer.
   * @returns 
   */
   saveAssessment(courseId: any, userId: any, assessmentId: any, questionId: any, userAnswer: any, returnAnswer: any): Observable<any> {
    return this.CourseService.saveAssessment(courseId, userId, assessmentId, questionId, userAnswer, returnAnswer);
  }

  /**
   * Submits the assessment details to the api.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @returns 
   */
  submitAssessment(courseId: any, userId: any, assessmentId: any): Observable<any> {
    return this.CourseService.submitAssessment(courseId, userId, assessmentId);
  }

  /**
   * Upload the files to the server.
   * @param formData the form data contains uploaded files information.
   * @returns 
   */
  uploadFileAttachment(formData: FormData): Observable<any> {
    return this.CourseService.uploadFileAttachment(formData);
  }

  /**
  * Sends the request to api for deleting the attachment related to question id.
  * @param userId the user id.
  * @param attemptId the attempt id.
  * @param questionId the question id.
  * @param attachmentId the attachment id.
  * @returns 
  */
  deleteAttachment(userId: number, attemptId: any, questionId: number, attachmentId: number): Observable<any> {
    return this.CourseService.deleteAttachment(userId, attemptId, questionId, attachmentId);
  }

  /**
   * Gets the markup data from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @returns 
   */
  getMarkup(userId: number, courseId: number, taskContentId: number): Observable<any> {
    return this.CourseService.getMarkup(userId, courseId, taskContentId);
  }

  /**
   * Saves the markup data to api.
   * @param formData the form data contains the mark up content.
   * @returns 
   */
  saveMarkup(formData: FormData): Observable<any> {
    return this.CourseService.saveMarkup(formData);
  }

  /**
   * Gets the page content information from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @returns 
   */
  getPageContent(userId: number, courseId: number, taskContentId: number): Observable<any> {
    return this.CourseService.getPageContent(userId, courseId, taskContentId);
  }

  /**
   * Gets the textarea input data from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @param elementId the element id.
   * @returns 
   */
  getInput(userId: number, courseId: number, taskContentId: number, elementId: number): Observable<any> {
    return this.CourseService.getInput(userId, courseId, taskContentId, elementId);
  }

  /**
   * Save the text area inpu data to api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @param elementId the element id.
   * @param userInputId the user input id.
   * @param userResponse the user response.
   * @returns 
   */
  saveInput(userId: number, courseId: number, taskContentId: number, elementId: number, userInputId: any, userResponse: string): Observable<any> {
    return this.CourseService.saveInput(userId, courseId, taskContentId, elementId, userInputId, userResponse);
  }

  /**
   * Loads the xml content from the given path.
   * @param path the xml path.
   * @returns 
   */
   loadXML(path: string): Observable<any> {
    return this.CourseService.loadXML(path);
  }
}
