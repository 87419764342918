import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiDictionary } from '../common/api-dictionary';
import { ApiService } from '../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  courseYearData = [];
  constructor(private apiService: ApiService) { }

  /**
   * Gets the user preferences.
   * @param userId The user id.
   */
  getPreferences(userId: any): Observable<any> {
    return this.apiService.get(ApiDictionary.getPreferences.url +
      '?response=application%2Fjson&' + '&user_id=' + userId)
      .pipe(map((data: any) => data));
  }
  /**
   * save the user preferences.
   * @param userId the userId
   * @param themeData the user selected preferences.
   */
  savePreferences(userId: number, themeData: any) {
    const params = new HttpParams()
      .append('response', 'application/json')
      .append('data', JSON.stringify(themeData))
      .append('user_id', userId);
    return this.apiService.get(ApiDictionary.savePreferences.url, params)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the course year list of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseData(courseId: any, userId: any): Observable<any> {
    return this.apiService.get(ApiDictionary.getCourseData.url +
      '?response=application%2Fjson&' + '&course_id=' + courseId + "&user_id=" + userId)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the course map details of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseMap(courseId: any, userId: any): Observable<any> {
    return this.apiService.get(ApiDictionary.getCourseMap.url +
      '?course_id=' + courseId + '&response=application%2Fjson&' + "user_id=" + userId)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the course grade details of the user.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getCourseGrades(courseId: any, userId: any): Observable<any> {
    return this.apiService.get(ApiDictionary.getCourseGrades.url + '?course_id=' + courseId + '&response=application%2Fjson' + "&user_id=" + userId)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the task data of the user.
   * @param courseId The course id.
   * @param lessonId The lesson id.
   * @param userId The user id.
   */
  getTaskList(courseId: any, lessonId: any, userId: any) {
    if (lessonId) {
      return this.apiService.get(ApiDictionary.getTaskList.url + '?course_id=' + courseId + '&lesson_id=' + lessonId + '&response=application%2Fjson' + "&user_id=" + userId)
        .pipe(map((data: any) => data));
    } else {
      return this.apiService.get(ApiDictionary.getTaskList.url + '?course_id=' + courseId + '&response=application%2Fjson' + "&user_id=" + userId)
        .pipe(map((data: any) => data));
    }
  }

  /**
   * Gets the assessment details of the user
   * @param assessmentId The assessmen id.
   * @param courseId The course id.
   * @param userId The user id.
   */
  getAssessment(assessmentId: string, courseId: any, userId: any) {
    return this.apiService.get(ApiDictionary.getAssessment.url + '?assessment_id=' + assessmentId + '&course_id=' + courseId + '&response=application%2Fjson' + "&user_id=" + userId)
      .pipe(map((data: any) => data));
  }

  /**
   * Sends the email to teacher.
   * @param courseId The course id.
   * @param emailBodyText The email body.
   * @param emailSubjectTxt The email subject.
   * @param userId The user id.
   */
  messageTeacher(courseId: any, emailBodyText: any, emailSubjectTxt: any, userId: any) {
    return this.apiService.get(ApiDictionary.messageTeacher.url + '?course_id=' + courseId + '&message_body=' + emailBodyText.trim() + '&subject_line=' + emailSubjectTxt + "&user_id=" + userId)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the course year data of the user.
   * @param schoolYearId The school year id.
   * @param userId The user id.
   */
  getCourseYear(schoolYearId: any, userId: any): Observable<any> {
    return this.courseYearData.length > 0 ? of(this.courseYearData) : this.apiService.get(ApiDictionary.getCoursesYear.url +
      '?response=application%2Fjson' + "&schoolyear_id=" + schoolYearId + '&user_id=' + userId)
      .pipe(map((data: any) => {
        this.courseYearData = data;
        return data;
      }));
  }

  /**
   * Starts a particular task of the user.
   * @param courseId The course id.
   * @param taskId The task id.
   * @param userId The user id.
   */
  startTask(courseId: any, taskId: any, userId: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.startTask.url}?response=application%2Fjson&course_id=${courseId}&id=${taskId}&user_id=${userId}`)
      .pipe(map((data: any) => data));
  };

  /**
   * Completes a particular task of the user
   * @param courseId The course id.
   * @param taskId The task id.
   * @param userId The user id.
   */
  completeTask(courseId: any, taskId: any, userId: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.completeTask.url}?response=application%2Fjson&course_id=${courseId}&id=${taskId}&user_id=${userId}`)
      .pipe(map((data: any) => data));
  };

  /**
   * Gets the course year data from local memory.
   */
  getCourseYearData(): Array<any> {
    return this.courseYearData;
  }

  /**
   * Saves the Notepad data.
   * @param course_id the course id
   * @param user_id  the user id
   * @param lesson_id  the lesson id
   * @param data  the notebook details
   * @returns 
   */
  saveNotePad(course_id: any, user_id: any, lesson_id: any, data: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.saveNotePad.url}?course_id=${course_id}&lesson_id=${lesson_id}&user_id=${user_id}&data=${data}`)
      .pipe(map((data: any) => data));

  }

  /**
   * gets the notepad data.
   * @param course_id the course id
   * @param user_id  the user id
   * @param lesson_id  the lesson id
   * @returns 
   */
  getNotePad(course_id: any, user_id: any, lesson_id: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.getNotePad.url}?response=application%2Fjson&course_id=${course_id}&lesson_id=${lesson_id}&user_id=${user_id}`)
      .pipe(map((data: any) => data));

  }

  /**
   * Starts the assessment.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @returns 
   */
  startAssessment(courseId: any, userId: any, assessmentId: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.startAssessment.url}?response=application%2Fjson&course_id=${courseId}&assessment_id=${assessmentId}&user_id=${userId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Saves the assessment details.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @param questionId the question id.
   * @param userAnswer the user answer.
   * @param returnAnswer the return answer.
   * @returns 
   */
  saveAssessment(courseId: any, userId: any, assessmentId: any, questionId: any, userAnswer: any, returnAnswer: any): Observable<any> {
    const url = `${ApiDictionary.saveAssessment.url}?response=application%2Fjson&course_id=${courseId}&assessment_id=${assessmentId}&user_id=${userId}&question_id=${questionId}&userAnswer=${encodeURIComponent(userAnswer)}&return_answer=${returnAnswer}`;
    return this.apiService.get(url).pipe(map((data: any) => data));
  }

  /**
   * Submits the assessment details to the api.
   * @param courseId the course id.
   * @param userId the user id.
   * @param assessmentId the assessment id.
   * @returns 
   */
  submitAssessment(courseId: any, userId: any, assessmentId: any): Observable<any> {
    return this.apiService.get(`${ApiDictionary.submitAssessment.url}?response=application%2Fjson&course_id=${courseId}&assessment_id=${assessmentId}&user_id=${userId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Upload the files to the server.
   * @param formData the form data contains uploaded files information.
   * @returns 
   */
  uploadFileAttachment(formData: FormData): Observable<any> {
    return this.apiService.upload(`${ApiDictionary.fileAttachment.url}`, formData).pipe(map((data: any) => data));
  }

  /**
   * Sends the request to api for deleting the attachment related to question id.
   * @param userId the user id.
   * @param attemptId the attempt id.
   * @param questionId the question id.
   * @param attachmentId the attachment id.
   * @returns 
   */
  deleteAttachment(userId: number, attemptId: any, questionId: number, attachmentId: number): Observable<any> {
    return this.apiService.get(`${ApiDictionary.deleteFileAttachment.url}?user_id=${userId}&attempt_id=${attemptId}&attachment_id=${attachmentId}&question_id=${questionId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the markup data from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @returns 
   */
  getMarkup(userId: number, courseId: number, taskContentId: number): Observable<any> {
    return this.apiService.get(`${ApiDictionary.getMarkup.url}?response=application%2Fjson&user_id=${userId}&course_id=${courseId}&taskContent_id=${taskContentId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Saves the markup data to api.
   * @param formData the form data contains the mark up content.
   * @returns 
   */
  saveMarkup(formData: FormData): Observable<any> {
    return this.apiService.upload(`${ApiDictionary.saveMarkup.url}`, formData).pipe(map((data: any) => data));
  }

  /**
   * Gets the page content information from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @returns 
   */
  getPageContent(userId: number, courseId: number, taskContentId: number): Observable<any> {
    return this.apiService.get(`${ApiDictionary.getPageContent.url}?response=application%2Fjson&user_id=${userId}&course_id=${courseId}&page_id=${taskContentId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Gets the textarea input data from api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @param elementId the element id.
   * @returns 
   */
  getInput(userId: number, courseId: number, taskContentId: number, elementId: number): Observable<any> {
    return this.apiService.get(`${ApiDictionary.getInput.url}?response=application%2Fjson&user_id=${userId}&course_id=${courseId}&taskContent_id=${taskContentId}&element_id=${elementId}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Save the text area inpu data to api.
   * @param userId the user id.
   * @param courseId the course id.
   * @param taskContentId the task content id.
   * @param elementId the element id.
   * @param userInputId the user input id.
   * @param userResponse the user response.
   * @returns 
   */
  saveInput(userId: number, courseId: number, taskContentId: number, elementId: number, userInputId: any, userResponse: string): Observable<any> {
    return this.apiService.get(`${ApiDictionary.saveInput.url}?response=application%2Fjson&user_id=${userId}&course_id=${courseId}&taskContent_id=${taskContentId}&element_id=${elementId}&userInput_id=${userInputId}&data=${userResponse}`)
      .pipe(map((data: any) => data));
  }

  /**
   * Loads the xml content from the given path.
   * @param path the xml path.
   * @returns 
   */
  loadXML(path: string): Observable<any> {
    return this.apiService.loadXML(path).pipe(map((data: any) => data));
  }
}
