import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CKEditorComponent } from 'ng2-ckeditor';
import { Utility, SessionService, elService } from '../../../core/services';
import { ckeEditorToolBarOptions } from '../../../core/constants';
import * as jquery from 'jquery';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadComponent implements OnInit {
  @ViewChild('myckeditor') ckeditor: CKEditorComponent | undefined;
  portrait = (window.orientation === 90 || window.orientation === -90);
  ckeConfig: any = CKEDITOR.config;
  notepadContent = '';
  searchText: string = '';
  feedBackState: boolean = false;
  feedBackMessage: string = '';
  disabledSaveBtn = true;
  activeTheme: any = {
    borderColor: '#FCA017',
    backgroundColor: '#FCB345'
  };
  dataRecieved: any;
  dataToSend: any = {};
  alertOptions: any = null;

  constructor(private utility: Utility, private sessionservice: SessionService, private elservice: elService, private router: Router) { }

  ngOnInit(): void {
    this.ckeConfig = ckeEditorToolBarOptions;
    this.searchText = '';
    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
    jquery(window).off('orientationchange').on('orientationchange', () => {
      if (isAndroid) {
        if (window.orientation === 90 || window.orientation === -90) {
          this.portrait = true;
        } else {
          this.portrait = false;
        }
      }
    });
    this.getNotePad();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1;
    if (this.portrait && isAndroid) {
      jquery('html, body').animate({
        scrollLeft: 100
      }, 800);
    }
  }

  /**
   * Triggers this event on web search control paste
   * @param event
   */
  webSearchOnPaste(event: any) {
    if (event) {
      let data = event.clipboardData.getData('text/plain');
      data = data.replace(/[^a-zA-Z0-9]/g, '');
      setTimeout(() => {
        this.searchText = data;
      }, 2);
    }
  }

  /**
   * Triggers this event on web search key press
   * @param event 
   */
  webSearchOnKeyPress(event: any) {
    const regex = new RegExp('^[a-zA-Z0-9._\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  /**
   * Triggers this event on notepad text change
   * @param event
   */
  onNotepadTextChange(event: any) {
    this.disabledSaveBtn = this.notepadContent.length === 0;
  }

  /**
   * Popup will be opened in new window for web search
   */
  redirectPopup() {
    if (this.searchText) {
      window.open('http://www.google.com/search?q=' + encodeURI(this.searchText));
    }
    else {
      this.feedBackMessage = 'Enter some text';
      this.feedBackState = true;
      this.hideFeedbackMsg();
    }
  }

  /**
   * Closes the notepad modal
   */
  closeNotepad() {
    this.notepadContent = '';
    this.utility.setNotepadValueBindEvent(null);
  }

  /**
   * Saves the notepad data
   */
  saveData() {
    if (this.sessionservice.launcherDetails.viewMode === false) {
      if (this.disabledSaveBtn) {
        return;
      }
      let data = this.notepadContent;
      if (data) {
        data = encodeURI(data);
        if (data.length <= 1500) {
          const lessonId = this.sessionservice.myDayViewDetails.lessonId;
          // const lessonId: number = !this.router.url.endsWith('myday') ? this.sessionservice.lessonDetails?.id : this.sessionservice.myDayViewDetails.lessonId;
          this.elservice.saveNotePad(this.sessionservice.launcherDetails.courseId, this.sessionservice.launcherDetails.userId, lessonId, data).
            subscribe((res: any) => {
              if (!res) {
                this.alertOptions = {
                  type: 'alert',
                  message: 'Your notepad not saved successfully due to technical issue.',
                  confirm: () => { this.closeAlertModal(); }
                };
                return;
              }
              this.feedBackMessage = 'Saved Successfully';
              this.feedBackState = true;
              this.hideFeedbackMsg();
              this.closepopup();
            });
        }
      }
      else {
        this.feedBackMessage = 'You have exceeded the max character limit (i.e 1500)';
        this.feedBackState = true;
        this.hideFeedbackMsg();
        this.closepopup();
      }
    }
  }

  /**
   * gets the notepad data
   */
  getNotePad() {
    if (this.sessionservice.launcherDetails.viewMode === false) {
      const lessonId = this.sessionservice.myDayViewDetails.lessonId;
      // const lessonId: number = !this.router.url.endsWith('myday') ? this.sessionservice.lessonDetails?.id : this.sessionservice.myDayViewDetails.lessonId;
      this.elservice.getNotePad(this.sessionservice.launcherDetails.courseId, this.sessionservice.launcherDetails.userId, lessonId).
        subscribe(data => {
          this.dataRecieved = data;
          this.dataToSend.title = this.dataRecieved.title;
          if (this.dataRecieved.text && typeof (this.dataRecieved.text) !== 'object') {
            this.notepadContent = decodeURI(this.dataRecieved.text);
            this.disabledSaveBtn = true;
          }
        });
    }
  }


  /**
   * Hides the feedback message
   */
  hideFeedbackMsg() {
    if (this.feedBackState === true) {
      setTimeout(() => {
        this.feedBackState = false;
      }, 2000);
    }
  }

  /**
   * Closes the alert options
   */
  closeAlertModal() {
    this.alertOptions = null;
  }

  closepopup() {
    setTimeout(() => {
      this.closeNotepad();
    }, 1000)
  }

}


