import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/core/services/common/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  pageName: any[] | undefined;
  breadcrums : any[] = [];

  constructor(private breadcrumbService: BreadcrumbService , private router : Router) { }

  ngOnInit() {
    this.breadcrumbService.currentName.subscribe((name:any[] | undefined)=>{
      this.pageName = name;
      if (this.pageName) {
        this.breadcrums = [];
        this.pageName.forEach(ele => {
          this.breadcrums.push({
            label: ele.label,
            url: ele.data,
            className:ele.className
          });
        });
      }
    });
  }
}
