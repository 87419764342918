import { Component, OnInit, Input } from '@angular/core';
import { Utility } from '../../../core/services';
import { SpecialChar } from '../../pipes/specialChar.pipe';
@Component({
  selector: 'app-custom-alert',
  templateUrl: './custom-alert.component.html',
  styleUrls: ['./custom-alert.component.scss'],
  providers: [SpecialChar]
})
export class CustomAlertComponent implements OnInit {
  alertMessage: string = '';
  alertType: string = '';
  activeTheme: any = null;
  displayAlertBox: boolean = false;
  confirmEvent: Function = () => {};
  dismissEvent: Function = () => {};
  alertMaxWidthClassName = '';

  @Input() 
  set alertOptions(data: any) {
    if (data) {
      this.alertMessage = this.specialChar.transform(data.message);
      this.alertType = data.type;
      this.displayAlertBox = data.confirm ? true : false;
      this.confirmEvent = data.confirm;
      this.dismissEvent = data.dismiss ? data.dismiss : () => {};
      this.alertMaxWidthClassName = data.alertMaxWidthClassName;
    } else {
      this.displayAlertBox = false;
      this.alertMessage = '';
      this.confirmEvent = () => {};
      this.dismissEvent = () => {};
      this.alertMaxWidthClassName = '';
    }
  }

  constructor(private utility: Utility, private specialChar: SpecialChar) { }

  ngOnInit(): void {
    this.utility.themObject.subscribe((data: any) => {
      this.activeTheme = data;
    });
  }

  /**
   * Triggers the confirm click event.
   */
  confirmClick() {
    this.confirmEvent();
  }

  /**
   * Triggers the dismiss event.
   */
  dismiss() {
    this.displayAlertBox = false;
    this.dismissEvent();
  }

}
