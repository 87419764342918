export class ThemeModel {
    activeColor: string = '';
    activeColorMD: string = '';
    activeImage: string = '4.jpg';
    activeImageMD: string = '4.jpg';
    activeThemeColor : string = '#FCB345, #FCAE39';
    activeThemeStroke : string = '#FCA017';
    activebgColor: string = '#D0953B';
    turnOffGameAssessments: boolean = false;
}


