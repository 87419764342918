
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader/loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  couter = 0;
  private totalRequests = 0;
  private loaderExcludedUrls = [];

  constructor(private loaderService: LoaderService) {

  }

  /**
   * Checks the api url is excluded from loader activity.
   * @param url the api url.
   */
  private isLoaderExcludedForUrl(url: string): boolean {
    let isExcluded = false;
    this.loaderExcludedUrls.forEach(u => {
      if (url.includes(u)) {
        isExcluded = true;
        return;
      }
    });
    return isExcluded;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isLoaderExcludedForUrl(request.url)) {
      this.loaderService.show();
      this.totalRequests++;
    }
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.onEnd();
        }
      }));

  }

  /**
   * Ends the loader activity
   */
  private onEnd(): void {
    this.hideLoader();
  }

  /**
   * Hides the loader.
   */
  private hideLoader(): void {
    this.loaderService.hide();
  }
}