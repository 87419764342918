import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { elService, Utility } from '../../../core/services';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  basicDetails: any;
  topHeader = true;
  constructor(private elservice: elService, private utility: Utility) { }

  ngOnInit() {
    this.utility.courseName.subscribe(val=>{
      this.basicDetails = val;
      this.topHeader = this.basicDetails?.courseName ? false : true;
    });
  }
  home(){
    window.location.href=environment.appUrl+"/web/student-portal/home";
  }
}
