import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlumpRendererService implements OnInit {
  m_movies: any[] = [];
  m_atlases: any[] = [];
  m_symbols: any[] = [];
  m_path = '';
  m_activeMovie: any = null;
  m_state = -1;
  m_speed = 1.0;
  m_loop = true;
  m_autoPlay = true;
  m_stage = [0, 0, 512, 512];
  m_keyframes: any = [];
  lastTime = 0;
  onLoad: Function = (data: any) => { };
  onLoop: Function = () => { };
  onEnd: Function = () => { };
  onProgress: Function = (m_nPos: any, posDurationRatio: any) => { };
  onPlay: Function = () => { };

  constructor() { }

  ngOnInit(): void {

  }

  /**
   * Resets the flump service details.
   */
  reset() {
    this.m_movies = [];
    this.m_atlases = [];
    this.m_symbols = [];
    this.m_path = '';
    this.m_activeMovie = null;
    this.m_state = -1;
    this.m_speed = 1.0;
    this.m_loop = true;
    this.m_autoPlay = true;
    this.m_stage = [0, 0, 512, 512];
    this.m_keyframes = [];
    this.lastTime = 0;
    this.onLoad = (data: any) => { };
    this.onLoop = () => { };
    this.onEnd = () => { };
    this.onProgress = (m_nPos: any, posDurationRatio: any) => { };
    this.onPlay = () => { };
  }

  /**
   * Parses the xml content.
   * @param xmlContent the xml string content.
   * @param path the path of the images in assets.
   */
  parse(xmlContent: string, path: string) {
    this.m_path = path;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'text/xml');
    const xmlNodes = xmlDoc.documentElement.childNodes;
    for (let index = 0; index < xmlNodes.length; index++) {
      const nodeName = xmlNodes[index].nodeName;
      switch (nodeName) {
        case 'movie':
          const movie = this.createMovieInstance();
          this.m_movies[this.m_movies.length] = movie;
          this.parseMovie(xmlNodes[index], movie);
          const symbol = this.createSymbolInstance(1, movie.m_sName, movie);
          this.m_symbols[this.m_symbols.length] = symbol;
          break;
        case 'textureGroups':
          const childNodes = xmlNodes[index].childNodes;
          this.parseTextureGroup(childNodes);
          break;
        default:
          break;
      }
    }
    for (let index = 0; index < this.m_movies.length; index++) {
      const layers = this.m_movies[index].m_layers;
      for (let count = 0; count < layers.length; count++) {
        const keyFrames = layers[count].m_keyframes;
        for (let idx = 0; idx < keyFrames.length; idx++) {
          const keyFrame = keyFrames[idx];
          if (keyFrame.m_sRef) {
            const symbol = this.m_symbols.find(t => t.m_sName === keyFrame.m_sRef);
            keyFrame.m_symbol = symbol;
            if (keyFrame.m_bTweened && keyFrame.m_nDuration == 1 && idx + 1 < keyFrames.length) {
              let keyFrameNext = keyFrames[idx + 1];
              keyFrame.m_bVisible = (!keyFrameNext.m_bVisible || keyFrameNext.m_sRef === '') ? false : keyFrame.m_bVisible;
            }
          }
        }
      }
    }
    this.m_state = 0;
  }

  /**
   * Renders the content on canvas element.
   * @param ctx the canvas element.
   * @param time 
   */
  render(ctx: any, time: any) {
    const ratio = ctx.canvas.width / ctx.canvas.height;
    const ratio2 = this.m_stage[2] / this.m_stage[3];
    if (ratio > ratio2) {
      const scale = ctx.canvas.height / this.m_stage[3];
      ctx.scale(scale, scale);
      ctx.translate(-this.m_stage[0], -this.m_stage[1]);
    } else {
      const scale = ctx.canvas.width / this.m_stage[2];
      ctx.scale(scale, scale);
      ctx.translate(-this.m_stage[0], -this.m_stage[1]);
    }
    if (this.m_activeMovie) {
      this.m_activeMovie.m_loop = this.m_loop;
      const rc = this.renderMovie(ctx, this.m_state === 1 ? time * this.m_speed : 0, null, this.m_activeMovie);
      if (rc === 1) {
        this.onLoop();
      } else if (rc === 2) {
        if (this.m_state === 1) {
          this.pause();
          this.onEnd();
        }
      } else if (typeof this.onProgress === 'function') {
        this.onProgress(this.m_activeMovie.m_nPos, this.m_activeMovie.m_nPos / this.m_activeMovie.m_nDuration);
      }
    }
  }

  /**
   * Sets the active movie name.
   * @param name the active movie name.
   * @returns 
   */
  setMovie(name: string | null) {
    if (this.m_state === -1) {
      return false;
    }
    this.m_activeMovie = this.m_movies.find(t => t.m_sName === name);
    return true;
  }

  /**
   * Plays the animation.
   */
  play() {
    if (this.m_state == -1) {
      return false;
    }
    this.onPlay();
    this.m_state = 1;
    return true;
  }

  /**
   * Pause the animation.
   */
  pause() {
    if (this.m_state === -1)
      return false;
    this.m_state = 2;
    return true;
  }

  /**
   * Stops the animation.
   */
  stop() {
    if (this.m_state == -1) {
      return false;
    }
    this.m_state = 0;
    if (this.m_activeMovie) {
      this.stopMovie(this.m_activeMovie);
    }
    return true;
  }

  /**
   * Callback function triggered after loading the atlas content.
   */
  atlasLoaded() {
    for (let index = 0; index < this.m_atlases.length; index++) {
      if (!this.m_atlases[index].m_loaded) { return; }
    }
    if (this.m_autoPlay) {
      this.play();
    }
    this.onLoad(true);
  }

  /**
   * Parses the texture resource group in child nodes.
   * @param childNodes the child nodes array.
   */
  private parseTextureGroup(childNodes: any) {
    for (let count = 0; count < childNodes.length; count++) {
      if (childNodes[count].nodeName === 'textureGroup') {
        const subChildNode = childNodes[count].childNodes;
        for (let idx = 0; idx < subChildNode.length; idx++) {
          if (subChildNode[idx].nodeName === 'atlas') {
            const atlas = this.createAtlasInstance();
            this.m_atlases[this.m_atlases.length] = atlas;
            this.parseAtlas(subChildNode[idx], this.m_path, atlas, (atlas: any) => {
              this.atlasLoaded();
            });
            for (let len = 0; len < atlas.m_textures.length; len++) {
              const symbol = this.createSymbolInstance(2, atlas.m_textures[len].m_sName, atlas.m_textures[len]);
              this.m_symbols[this.m_symbols.length] = symbol;
            }
          }
        }
      }
    }
  }

  //#region movie. 
  /**
   * Creates the movie object.
   */
  private createMovieInstance() {
    const movie: any = {
      m_sName: '',
      m_layers: [],
      m_nFrameRate: 24,
      m_loop: true,
      m_nPos: 0,
      m_nPosCumulative: 0
    };
    return movie;
  }

  /**
   * Parses the movie section in xml child nodes.
   * @param element the xml node element.
   * @param movie the movie instance.
   */
  private parseMovie(element: any, movie: any) {
    if (element.hasAttribute('name')) {
      movie.m_sName = element.getAttribute('name');
    }
    if (element.hasAttribute('frameRate')) {
      movie.m_nFrameRate = Number(element.getAttribute('frameRate'));
    }
    const childNodes = element.childNodes;
    for (let index = 0; index < childNodes.length; index++) {
      const name = childNodes[index].nodeName;
      switch (name) {
        case 'layer':
          const layer = this.createLayerInstance();
          movie.m_layers[movie.m_layers.length] = layer;
          this.parseLayer(childNodes[index], layer);
          movie.m_nDuration = movie.m_layers[0].m_nDuration;
          break;
        default:
          break;
      }
    }
    movie.m_nDuration /= movie.m_nFrameRate;
  }

  /**
   * Renders the movie on canvas.
   * @param ctx the canvas element.
   * @param time the time.
   * @param pos the pos.
   * @param movie the movie instance.
   * @returns 
   */
  private renderMovie(ctx: any, time: any, pos: any, movie: any) {
    let rc = 0;
    if (pos) {
      movie.m_nPos = pos % movie.m_nDuration;
      movie.m_nPosCumulative = pos;
    } else {
      movie.m_nPos += time;
      movie.m_nPosCumulative += time;
      if (movie.m_nPos >= movie.m_nDuration) {
        if (movie.m_loop) {
          movie.m_nPos %= movie.m_nDuration;
          rc = 1;
        } else {
          movie.m_nPosCumulative -= movie.m_nPos - movie.m_nDuration;
          movie.m_nPos = movie.m_nDuration;
          rc = 2;
        }
      }
    }
    for (let index = 0; index < movie.m_layers.length; index++) {
      this.renderLayer(ctx, Math.floor(movie.m_nPos * movie.m_nFrameRate), movie.m_nPosCumulative, movie.m_layers[index]);
    }
    return rc;
  }

  /**
   * Stops the movie.
   * @param movie the movie instance.
   */
  private stopMovie(movie: any) {
    movie.m_nPos = movie.m_nPosCumulative = 0;
  }

  /**
   * Creates the symbol class instance.
   * @param type the type of symbol.
   * @param name the name of symbol.
   * @param object the movie/texture object.
   * @returns 
   */
  private createSymbolInstance(type: any, name: string, object: any) {
    const symbol: any = {
      m_nType: type,
      m_sName: name,
      m_object: object
    };
    return symbol;
  }

  /**
   * Creates the layer instance.
   */
  private createLayerInstance() {
    const layer: any = {
      m_keyframes: [],
      m_nCurFrame: 0,
      m_nDuration: 0
    };
    return layer;
  }

  /**
   * Parses the layer section in xml child nodes. 
   * @param element the xml node element.
   * @param layer the layer instance
   */
  private parseLayer(element: any, layer: any) {
    if (element.hasAttribute('name')) {
      layer.m_sName = element.getAttribute('name');
    }
    const childNodes = element.childNodes;
    let duration = 0;
    for (let index = 0; index < childNodes.length; index++) {
      const name = childNodes[index].nodeName;
      switch (name) {
        case 'kf':
          const kf = this.createKeyFrameInstance();
          layer.m_keyframes[layer.m_keyframes.length] = kf;
          this.parseKeyFrame(childNodes[index], kf);
          kf.m_nPosition = duration;
          duration += kf.m_nDuration;
          break;
        default:
          break;
      }
    }
    layer.m_nDuration = duration;
  }

  /**
   * Renders the layer of movie on canvas.
   * @param ctx the canvas element.
   * @param frame the frame.
   * @param pos the pos.
   * @param layer the layer class intance.
   */
  private renderLayer(ctx: any, frame: any, pos: any, layer: any) {
    let nKF = layer.m_nCurFrame;
    let kf = layer.m_keyframes[layer.m_nCurFrame];
    if (frame < kf.m_nPosition) {
      nKF = 0;
    }
    else if (frame > kf.m_nPosition + kf.m_nDuration) {
      nKF++;
    }
    for (; nKF < layer.m_keyframes.length; nKF++) {
      let nDuration = layer.m_keyframes[nKF].m_nPosition + layer.m_keyframes[nKF].m_nDuration;
      if (nDuration >= frame)
        break;
    }
    nKF = (nKF >= layer.m_keyframes.length) ? 0 : nKF;
    layer.m_nCurFrame = nKF;
    kf = layer.m_keyframes[nKF];
    let kfNext = null;
    kfNext = (nKF + 1 < layer.m_keyframes.length) ? layer.m_keyframes[nKF + 1] : kfNext;
    let nDuration = kf.m_nPosition + kf.m_nDuration;
    if (!kf.m_bVisible || !kf.m_symbol) { return; }
    let x = kf.m_nX, y = kf.m_nY, scaleX = kf.m_nScaleX, scaleY = kf.m_nScaleY,
      skewX = kf.m_nSkewX, skewY = kf.m_nSkewY, alpha = kf.m_nAlpha;
    if (kf.m_bTweened && kfNext && kfNext.m_symbol) {
      let d = 1 - (nDuration - frame) / kf.m_nDuration;
      let ease = kf.m_nEase;
      if (ease !== 0) {
        let t;
        if (ease < 0) {
          const inv = 1 - d;
          t = 1 - inv * inv;
          ease = -ease;
        } else {
          t = d * d;
        }
        d = ease * t + (1 - ease) * d;
      }
      x += (kfNext.m_nX - x) * d;
      y += (kfNext.m_nY - y) * d;
      scaleX += (kfNext.m_nScaleX - scaleX) * d;
      scaleY += (kfNext.m_nScaleY - scaleY) * d;
      skewX += (kfNext.m_nSkewX - skewX) * d;
      skewY += (kfNext.m_nSkewY - skewY) * d;
      alpha += (kfNext.m_nAlpha - alpha) * d;
    }
    ctx.save();
    const sinX = Math.sin(skewX), cosX = Math.cos(skewX);
    const sinY = Math.sin(skewY), cosY = Math.cos(skewY);
    ctx.globalAlpha = alpha;
    ctx.transform(cosY * scaleX, sinY * scaleX, -sinX * scaleY, cosX * scaleY, x, y);
    ctx.translate(-kf.m_nPivotX, -kf.m_nPivotY);
    if (kf.m_symbol.m_nType === 1) {
      this.renderMovie(ctx, 0, pos, kf.m_symbol.m_object);
    } else if (kf.m_symbol.m_nType === 2) {
      const texture = kf.m_symbol.m_object;
      ctx.drawImage(texture.m_image, texture.m_nX, texture.m_nY, texture.m_nW, texture.m_nH, 0, 0, texture.m_nW, texture.m_nH);
    }
    ctx.restore();
  }

  /**
   * Creates the key frame class instance.
   */
  private createKeyFrameInstance() {
    const keyFrame: any = {
      m_nDuration: 0,
      m_nPosition: 0,
      m_sRef: '',
      m_symbol: null,
      m_sLabel: '',
      m_nX: 0,
      m_nY: 0,
      m_nScaleX: 1,
      m_nScaleY: 1,
      m_nSkewX: 0,
      m_nSkewY: 0,
      m_nPivotX: 1,
      m_nPivotY: 1,
      m_nAlpha: 1,
      m_bVisible: true,
      m_bTweened: true,
      m_nEase: 0
    };
    return keyFrame;
  }

  /**
   * Parses the keyframe element in xml child nodes.
   * @param element the xml node element.
   * @param keyFrame the keyframe class instance.
   */
  private parseKeyFrame(element: any, keyFrame: any) {
    if (element.hasAttribute('duration')) {
      keyFrame.m_nDuration = Number(element.getAttribute('duration'));
    }
    if (element.hasAttribute('ref')) {
      keyFrame.m_sRef = element.getAttribute('ref');
    }
    if (element.hasAttribute('label')) {
      keyFrame.m_sLabel = element.getAttribute('label');
    }
    if (element.hasAttribute('loc')) {
      const locAttr = element.getAttribute('loc').split(',');
      keyFrame.m_nX = Number(locAttr[0]);
      keyFrame.m_nY = Number(locAttr[1]);
    }
    if (element.hasAttribute('scale')) {
      const scaleAttr = element.getAttribute('scale').split(',');
      keyFrame.m_nScaleX = Number(scaleAttr[0]);
      keyFrame.m_nScaleY = Number(scaleAttr[1]);
    }
    if (element.hasAttribute('skew')) {
      const skewAttr = element.getAttribute('skew').split(',');
      keyFrame.m_nSkewX = Number(skewAttr[0]);
      keyFrame.m_nSkewY = Number(skewAttr[1]);
    }
    if (element.hasAttribute('pivot')) {
      const pivotAttr = element.getAttribute('pivot').split(',');
      keyFrame.m_nPivotX = Number(pivotAttr[0]);
      keyFrame.m_nPivotY = Number(pivotAttr[1]);
    }
    if (element.hasAttribute('alpha')) {
      keyFrame.m_nAlpha = Number(element.getAttribute('alpha'));
    }
    if (element.hasAttribute('visible')) {
      keyFrame.m_bVisible = this.toBoolean(element.getAttribute('visible'));
    }
    if (element.hasAttribute('tweened')) {
      keyFrame.m_bTweened = this.toBoolean(element.getAttribute('tweened'));
    }
    if (element.hasAttribute('ease')) {
      keyFrame.m_nEase = Number(element.getAttribute('ease'));
    }
  }
  //#endregion

  //#region texture group 
  /**
   * Creates the atlas class instance.
   */
  private createAtlasInstance() {
    const atlas: any = {
      m_textures: [],
      m_image: null,
      m_loaded: false,
      m_sFile: ''
    };
    return atlas;
  }

  /**
   * Parses the atlas element in xml child nodes.
   * @param element the xml node element
   * @param path the path of the images in assets
   * @param atlas the atlas class instance.
   * @param onLoad the onload callback.
   */
  private parseAtlas(element: any, path: string, atlas: any, onLoad: Function) {
    if (element.hasAttribute('file')) {
      atlas.m_sFile = element.getAttribute('file');
    }
    let fName = atlas.m_sFile.split('/');
    fName = path + fName[fName.length - 1];
    atlas.m_image = new Image();
    atlas.m_image.src = fName;
    atlas.m_image.onload = () => {
      atlas.m_loaded = true;
      onLoad();
    }
    const childNodes = element.childNodes;
    for (let index = 0; index < childNodes.length; index++) {
      const name = childNodes[index].nodeName;
      switch (name) {
        case 'texture':
          const texture = this.createTextureInstance(atlas.m_image);
          atlas.m_textures[atlas.m_textures.length] = texture;
          this.parseTexture(childNodes[index], texture);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Creates the texture class instance.
   * @param image the path of the images in assets
   */
  private createTextureInstance(image: any) {
    const texture: any = {
      m_sName: '',
      m_image: image,
      m_nX: 0,
      m_nY: 0,
      m_nW: 0,
      m_nH: 0,
      m_nOriginX: 0,
      m_nOriginY: 0,
      m_nOffsetX: 0,
      m_nOffsetY: 0
    };
    return texture;
  }

  /**
   * Parses the texture in child nodes.
   * @param element the xml node element.
   * @param texture the texture class instance.
   */
  private parseTexture(element: any, texture: any) {
    if (element.hasAttribute('name')) {
      texture.m_sName = element.getAttribute('name');
    }
    if (element.hasAttribute('rect')) {
      const rectAttr = element.getAttribute('rect').split(',');
      texture.m_nX = Number(rectAttr[0]);
      texture.m_nY = Number(rectAttr[1]);
      texture.m_nW = Number(rectAttr[2]);
      texture.m_nH = Number(rectAttr[3]);
    }
    if (element.hasAttribute('origin')) {
      const originAttr = element.getAttribute('origin').split(',');
      texture.m_nOriginX = Number(originAttr[0]);
      texture.m_nOriginY = Number(originAttr[1]);
    }
    if (element.hasAttribute('offset')) {
      const offsetAttr = element.getAttribute('offset').split(',');
      texture.m_nOffsetX = Number(offsetAttr[0]);
      texture.m_nOffsetY = Number(offsetAttr[1]);
    }
  }

  //#endregion

  /**
   * Returns the converted boolean data type.
   * @param value the value.
   * @returns 
   */
  private toBoolean(value: any) {
    return value.toLowerCase() === 'true' || value === '1';
  }
}
