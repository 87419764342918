<div class="header">
    <div class="logoArea" style="display:flex">
        <div class="iconImages">
            <img alt="edisonlearning" src="../../../../assets/images/edisonlearning-logo.svg" height="40px" />
        </div>
        <div class="iconImages">
            <a href="javascript:void(0)" (click)="home()" id="routeToPortal"><img src="../../../../assets/images/Home.gif" height="30px"
                    weight="30px"></a>
        </div>
    </div>
    <div texthelpCmd="jump:assessment_QuestionArea" class="playerArea" *ngIf="!topHeader">
        {{basicDetails?.courseName}}
    </div>
    <div class="playerArea" *ngIf="topHeader">
        MyDay
    </div>
    <div class="userArea">{{basicDetails?.userName}}</div>
</div>